<template>
  <section class="bg-white dark:bg-[#141414] w-full min-h-screen text-gray-900 dark:text-white">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="flex items-center mb-6 text-2xl font-semibold">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>
      <div class="flex items-center gap-1 w-full max-w-[440px] mb-3 px-2">
        <button
          v-for="page in pages"
          :key="page"
          :class="[
            'w-1/2 h-2 rounded-12 transition-colors duration-300 ease-linear',
            page <= currentPage ? 'bg-blue-700' : 'bg-gray-300 dark:bg-gray-600',
          ]"
          @click="handleChangePage(page)"
        />
      </div>
      <div class="w-full rounded-24 border primary-border max-w-[440px] px-4 py-8 md:px-8 md:py-10">
        <div class="flex items-center justify-between">
          <p class="text-base text-gray-500">Please fill this quick survey.</p>

          <button
            class="text-12 font-medium text-blue-700 hover:text-blue-900 dark:text-blue-500 dark:hover:text-blue-300"
            data-test="skip-survey"
            @click="
              () => {
                if (currentPage < pages.length) {
                  currentPage += 1
                  return
                }
                router.push('/')
              }
            "
          >
            Skip
          </button>
        </div>
        <div v-if="currentPage === 1">
          <h2 class="text-24 font-bold mt-1 mb-6">How did you hear about us?</h2>
          <div class="flex flex-col gap-4">
            <SimpleRadioInput
              v-for="(item, index) in heard_about_us_options"
              :key="index"
              :label="item.label"
              :checked="responses.heard_about_us === item.label"
              class="!text-15 !font-medium"
              @toggle="responses.heard_about_us = item.label"
            />
            <input
              v-if="responses.heard_about_us === 'Others'"
              v-model="responses.heard_about_us_other"
              class="primary-input"
              placeholder="Please specify"
            />
          </div>
          <button class="primary-btn mt-4 !w-full" @click="handleContinueClick">Continue</button>
        </div>
        <div v-else-if="currentPage === 2">
          <h2 class="text-24 font-bold mt-1 mb-6">How do you plan to use Dot?</h2>
          <div class="flex flex-col gap-4">
            <SimpleRadioInput
              v-for="(item, index) in dot_usage_options"
              :key="index"
              :label="item.label"
              :checked="responses.dot_usage === item.label"
              class="!text-15 !font-medium"
              @toggle="responses.dot_usage = item.label"
            />
            <textarea
              v-if="responses.dot_usage === 'Others'"
              v-model="responses.dot_usage_other"
              rows="3"
              class="primary-input"
              placeholder="Please specify"
            />
          </div>
          <button class="primary-btn mt-4 !w-full" @click="submitSurvey">
            <LoadingIcon v-if="submitting" class="w-6 h-6 text-white dark:text-gray-400 animate-spin" />
            Submit
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import { ref } from 'vue'

  import { useRouter } from 'vue-router'
  import axios from '@/axiosInstance'
  import LoadingIcon from './components/icons/LoadingIcon.vue'
  import { useGlobalStore } from './stores/globalStore'
  import SimpleRadioInput from './components/SimpleRadioInput.vue'
  import { useUserStore } from './stores/userStore'
  import { storeToRefs } from 'pinia'

  const router = useRouter()

  const globalStore = useGlobalStore()
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)
  const notify = globalStore.notify
  const submitting = ref(false)

  const heard_about_us_options = ref([
    { value: 'linkedin', label: 'LinkedIn' },
    { value: 'google', label: 'Google' },
    { value: 'conference', label: 'Conference' },
    { value: 'hacker_news', label: 'HackerNews' },
    { value: 'medium', label: 'Medium' },
    { value: 'friend_or_colleague', label: 'Friend or Colleague' },
    { value: 'others', label: 'Others' },
  ])

  const dot_usage_options = ref([
    { value: 'self_service_analytics', label: 'For self-service analytics for business users' },
    { value: 'data_discovery', label: 'For data discovery' },
    { value: 'database_info_lookup', label: 'For looking up information in databases' },
    { value: 'others', label: 'Others' },
  ])

  const responses = ref({
    heard_about_us: '',
    heard_about_us_other: '',
    dot_usage: '',
    dot_usage_other: '',
  })

  const pages = ref([1, 2])
  const currentPage = ref(1)

  const handleChangePage = p => {
    if (responses.value.heard_about_us === 'others' && !responses.value.heard_about_us_other) {
      notify.error('Please specify how you heard about us')
      return
    }

    currentPage.value = p
  }

  const handleContinueClick = () => {
    if (responses.value.heard_about_us === 'others' && !responses.value.heard_about_us_other) {
      notify.error('Please specify how you heard about us')
      return
    }
    currentPage.value = 2
  }

  const submitSurvey = async () => {
    const body = {
      user_id: user?.value.id,
      org_id: user?.value.org_id,
      heard_about_us: responses.value.heard_about_us,
      heard_about_us_other: responses.value.heard_about_us_other,
      dot_usage: responses.value.dot_usage,
      dot_usage_other: responses.value.dot_usage_other,
    }

    try {
      submitting.value = true
      await axios.post('/api/survey', body)
      notify.success('Success. Thank you for filling the survey!')
      router.push('/')
    } catch (error) {
      console.error(error)
      notify.error('Failed to submit survey')
    } finally {
      submitting.value = false
    }
  }
</script>

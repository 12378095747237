/**
 * Handles shift+click selection for checkboxes with multi-deselect functionality.
 *
 * @param {number} currentIndex - The index of the currently clicked checkbox.
 * @param {object} currentItem - The current item being clicked.
 * @param {Array} items - The array of items in the table.
 * @param {string} toggleProperty - The property to toggle (e.g., 'active').
 * @param {number|null} lastSelectedIndex - The index of the last selected checkbox.
 * @param {function} updateLastIndex - Callback to update the last selected index.
 * @param {Event} event - The click event.
 */
export function handleShiftClickSelection(
  currentIndex,
  currentItem,
  items,
  toggleProperty,
  lastSelectedIndex,
  updateLastIndex,
  event
) {
  if (lastSelectedIndex !== null && event.shiftKey) {
    // Determine the range to select/deselect
    const start = Math.min(lastSelectedIndex, currentIndex)
    const end = Math.max(lastSelectedIndex, currentIndex)

    // Check if all items in the range are currently selected
    const allSelected = items.slice(start, end + 1).every(item => !item.disabled && item[toggleProperty])

    // Toggle the selected property for all items in the range
    for (let i = start; i <= end; i++) {
      if (!items[i].disabled) {
        items[i][toggleProperty] = !allSelected // Deselect if all are selected, else select
      }
    }
  } else {
    // Toggle the current item's property
    currentItem[toggleProperty] = !currentItem[toggleProperty]
  }

  // Update the last selected index
  updateLastIndex(currentIndex)
}

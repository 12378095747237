<template>
  <div id="billing" class="p-4 rounded-xl bg-gray-50 dark:bg-gray-900">
    <div class="flex justify-center gap-6 flex-col sm:flex-row">
      <div
        :class="{
          'dark:border-gray-600 border-gray-100': subscription_status,
          'dark:border-green-600 border-green-600': !subscription_status,
        }"
        class="flex flex-col p-6 w-full sm:w-[350px] max-w-[350px] mx-auto sm:mx-0 sm:max-w-lg text-center text-gray-900 bg-white rounded-xl border shadow xl:p-8 dark:bg-gray-900 dark:text-white relative"
      >
        <p
          v-if="!subscription_status"
          class="text-gray-500 font-mono text-xs dark:text-gray-400 mb-2 absolute bottom-3 left-1/2 -translate-x-1/2 text-green-600 dark:text-green-300"
        >
          Active
        </p>
        <h3 class="text-2xl font-semibold">STARTER</h3>
        <div class="flex justify-center items-baseline my-8">
          <span class="mr-2 text-5xl font-extrabold">Free</span>
        </div>
        <p class="text-gray-500 sm:text-lg dark:text-gray-400 mb-8">Starter includes</p>
        <!-- List -->
        <ul role="list" class="mb-16 space-y-4 text-left">
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>3 active tables</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>100 messages + 10 / month</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Chat</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Model</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Evaluate</span>
          </li>
        </ul>
      </div>

      <div
        :class="{
          'dark:border-gray-600': !subscription_status,
          'border-gray-100': !subscription_status,
          'dark:border-green-600': subscription_status,
          'border-green-600': subscription_status,
        }"
        class="flex flex-col p-6 w-full sm:w-[350px] max-w-[350px] mx-auto sm:mx-0 sm:max-w-lg text-center text-gray-900 bg-white rounded-xl border shadow xl:p-8 dark:bg-gray-900 dark:text-white relative"
      >
        <p
          v-if="subscription_status"
          class="text-gray-500 font-mono text-xs dark:text-gray-400 mb-2 absolute top-3 left-1/2 -translate-x-1/2 text-green-600 dark:text-green-300"
        >
          Active
        </p>
        <h3 class="text-2xl font-semibold">STANDARD</h3>
        <div class="flex justify-center items-baseline my-8">
          <span v-if="subscription_currency === 'usd'" class="mr-2 text-5xl font-extrabold">
            ${{ subscription_price }}
          </span>
          <span v-else-if="subscription_currency === 'eur'" class="mr-2 text-5xl font-extrabold">
            {{ subscription_price }}€
          </span>
          <span v-else class="mr-2 text-5xl font-extrabold">{{ subscription_price }} {{ subscription_currency }}</span>
          <span class="text-gray-500 dark:text-gray-400">/month</span>
        </div>
        <p class="text-gray-500 sm:text-lg dark:text-gray-400 mb-8">Everything in Starter +</p>
        <!-- List -->
        <ul role="list" class="mb-7 space-y-4 text-left">
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Up to 500 users</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Up to 500 messages / month</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>15 active tables</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Live chat support</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Priority onboarding</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>API access</span>
          </li>
        </ul>
        <div v-if="user && user.org_id && user.email">
          <div v-if="subscription_status">
            <button
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              @click.prevent="goToCustomerPortal"
            >
              <span>Manage subscription</span>
            </button>
          </div>
          <div v-else>
            <button
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              @click.prevent="goToSubscription"
            >
              <span>Subscribe</span>
            </button>
          </div>
        </div>
      </div>
      <div
        :class="{
          'dark:border-gray-600': !subscription_status,
          'border-gray-100': !subscription_status,
        }"
        class="flex flex-col p-6 w-full sm:w-[350px] max-w-[350px] mx-auto sm:mx-0 sm:max-w-lg text-center text-gray-900 bg-white rounded-xl border shadow xl:p-8 dark:bg-gray-900 dark:text-white relative"
      >
        <p
          v-if="subscription_status"
          class="text-gray-500 font-mono text-xs dark:text-gray-400 mb-2 absolute top-3 left-1/2 -translate-x-1/2 text-green-600 dark:text-green-300"
        >
          Active
        </p>
        <h3 class="text-2xl font-semibold">ENTERPRISE</h3>
        <div class="flex justify-center items-baseline my-8">
          <span class="mr-2 text-5xl font-extrabold">Custom</span>
        </div>
        <p class="text-gray-500 sm:text-lg dark:text-gray-400 mb-8">Everything in Standard +</p>
        <!-- List -->
        <ul role="list" class="mb-7 space-y-4 text-left">
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Unlimited Users</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Volume Discounts</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Self-hosted environment</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Priority Support</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Tailored training & onboarding</span>
          </li>
          <li class="flex items-center space-x-3">
            <!-- Icon -->
            <svg
              class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Single-Sign On</span>
          </li>
        </ul>
        <div v-if="user && user.org_id && user.email">
          <div>
            <a href="https://calendly.com/theo-sled/dot-the-data-bot-clone" target="_blank">
              <button
                class="text-dark-text dark:text-white bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-800 dark:hover:bg-gray-950 dark:focus:ring-gray-800"
              >
                <span>Talk to Théo</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { onMounted } from 'vue'
  import axios from '@/axiosInstance'
  import { useUserStore } from '@/stores/userStore'
  import { useGlobalStore } from '@/stores/globalStore'
  import { storeToRefs } from 'pinia'
  import { useRoute } from 'vue-router'

  export default {
    emits: ['show-notification'],
    setup(props, { emit }) {
      const route = useRoute()

      const userStore = useUserStore()

      const { user } = storeToRefs(userStore)

      const globalStore = useGlobalStore()

      const { subscription_status, subscription_price, subscription_currency } = storeToRefs(globalStore)

      async function getCustomerPortalURL() {
        const response = await axios.get('/api/create_customer_portal_session', {
          withCredentials: true,
        })
        return response.data
      }

      async function goToCustomerPortal() {
        const customerPortalURL = await getCustomerPortalURL()
        window.location.href = customerPortalURL
      }

      async function getSubscriptionURL() {
        const response = await axios.get('/api/create_subscription_session', {
          withCredentials: true,
        })
        return response.data
      }

      async function goToSubscription() {
        const subscriptionURL = await getSubscriptionURL()
        window.location.href = subscriptionURL
      }

      onMounted(() => {
        document.title = 'Billing · Settings · Dot'

        // If query param status is success, show success notification
        if (route.query.status === 'success') {
          emit('show-notification', {
            message: 'Subscription updated successfully.',
            isSuccess: true,
          })
        } else if (route.query.status === 'cancel') {
          emit('show-notification', {
            message: 'Subscription update cancelled.',
            isSuccess: false,
          })
        }

        globalStore.getSubscriptionStatusFromServer()
      })

      return {
        user,
        subscription_status,
        subscription_price,
        subscription_currency,
        goToCustomerPortal,
        goToSubscription,
      }
    },
  }
</script>

<!-- Settings Drawer -->
<template>
  <div>
    <Popup ref="saveChangesPopup" @cancel="discardItem()" @confirm="saveItem()">
      <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
        <h2 class="text-xl font-bold mb-2">Save Changes</h2>
        <p class="text-sm">You have unsaved changes. Do you want to save them?</p>
      </div>
    </Popup>
    <div v-if="isMobileDevice" class="px-[24px] pb-12 bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full">
      Please switch to a bigger device to view the model page.
    </div>
    <div
      v-else
      class="px-1 pb-12 py-4 overflow-y-scroll bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full"
    >
      <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Note</h3>
      <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
        Include essential information for Dot, such as details about your company, products, and common abbreviations.
      </p>
      <!-- Note as textarea -->
      <ResizableTextarea
        :min-rows="3"
        :model-value="org_note"
        placeholder="TinyToy is manufacturer of children's toys. We sell our products to retailers and distributors. Our top products are tiny cars and tiny trucks."
        @update:model-value="((org_note = $event), blockTable('company_note'))"
      />
      <div v-if="isItemBlockedByCurrentUser('company_note')" class="mt-2 flex items-center gap-2">
        <button :disabled="loading_suggestion" class="gray-btn" @click="suggestNote">
          <Icon v-if="!loading_suggestion" icon="hugeicons:sparkles" class="w-4 h-4 text-[#262626] dark:text-white" />
          <LoadingIcon v-else class="!w-4 !h-4 text-gray-500 dark:text-gray-300 animate-spin" />

          <span>Suggest</span>
        </button>
        <button
          class="primary-btn"
          :disabled="!org_note || org_note.length === 0 || !orgNoteChanged"
          @click="(saveNote(), unblockTable('company_note'))"
        >
          <Icon icon="mi:circle-arrow-up" class="w-4 h-4" />
          <span>Save</span>
        </button>
      </div>
      <div v-else class="mt-2 flex items-center gap-2">
        <button
          v-tooltip="'Forcefully unblocking this element might remove changes made by the other user.'"
          class="ml-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-xl hover:bg-red-700 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-800"
          @click="takeOverTable('company_note')"
        >
          <Icon icon="mdi:account-cancel" class="w-4 h-4 mr-2" />
          <div class="flex flex-col text-left">
            <span>Take Over</span>
            <span v-if="itemBlockedBy('company_note')?.user_id !== userStore.user.id" class="text-xs">
              Blocked by User {{ itemBlockedBy('company_note')?.user_id }} since
              {{ formatDistanceToNow(parseISO(itemBlockedBy('company_note')?.timestamp), { addSuffix: true }) }}
            </span>
            <span v-else class="text-xs">
              Blocked by yourself in another window since
              {{ formatDistanceToNow(parseISO(itemBlockedBy('company_note')?.timestamp), { addSuffix: true }) }}
            </span>
          </div>
        </button>
      </div>
      <div class="h-10" />

      <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Data</h3>
      <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
        Select the data sources Dot should know about.
        <br />
        Click on a data source to configure its descriptions, columns, metrics and permissions.
      </p>
      <div class="h-5" />

      <!-- Table for data start -->
      <div class="flex items-center justify-between gap-4 flex-wrap">
        <Search :model-value="search" placeholder="Search..." @update:model-value="search = $event" @input="page = 1" />

        <div class="relative">
          <button class="gray-btn" @click="showTableGroupsDropdown = !showTableGroupsDropdown">
            <span>Filter by group</span>
          </button>
          <div
            v-if="showTableGroupsDropdown"
            v-click-away="() => (showTableGroupsDropdown = false)"
            class="absolute z-20 bg-white rounded-xl shadow w-60 dark:bg-gray-800 right-0 top-[calc(100%+10px)]"
          >
            <div class="p-1 pb-0">
              <Search
                :model-value="search_table_groups"
                placeholder="Search.."
                class="!p-2"
                @update:model-value="search_table_groups = $event"
              />
            </div>
            <ul class="h-48 px-3 py-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200">
              <li v-for="group in allTableGroups" :key="group.value">
                <div class="flex items-center pl-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700">
                  <input
                    :id="group.value"
                    type="checkbox"
                    :checked="selectedTableGroups.includes(group.value)"
                    class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    @change="toggleTableGroup(group.value)"
                  />
                  <label
                    :for="group.value"
                    class="w-full p-1 cursor-pointer ms-2 text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                  >
                    {{ group.label }} {{ `(${group.count})` }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="table-wrapper">
        <table class="primary-table">
          <thead class="table-header">
            <tr>
              <th scope="col" class="p-2 pl-4 sm:rounded-tl-xl">Active</th>
              <th scope="col" class="px-6 py-3">
                <div class="flex items-center">Data Source</div>
              </th>
              <th scope="col" class="px-6 py-3">Description</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              v-for="item in filteredTableItems"
              :key="item.id"
              class="bg-white dark:bg-gray-950 hover:bg-gray-50 dark:hover:bg-gray-900"
              :class="{
                'cursor-pointer': table_loading !== item.id,
                'rounded-bl-xl': item.id === filteredTableItems[filteredTableItems.length - 1].id,
              }"
              :disabled="table_loading === item.id"
              @click="selectTable(item)"
            >
              <td class="w-4 p-2">
                <div v-if="table_loading !== item.id" class="flex items-center">
                  <input
                    id="checkbox-table-search-1"
                    v-tooltip="item.active ? 'Deactivate' : 'Activate'"
                    type="checkbox"
                    :checked="item.active"
                    class="w-5 h-5 mx-auto text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    @click.stop="toggleTableStatus(item)"
                  />
                  <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                </div>
                <div v-else class="flex items-center">
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-5 h-5 mx-auto text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </td>
              <th
                v-tooltip="item.name ? `Edit ${item.name}` : `Edit data source`"
                scope="row"
                alt="item.name"
                class="pl-6 pr-2 py-4 text-xs max-w-md truncate font-medium whitespace-nowrap flex items-center font-mono"
                :class="
                  item.archived ? 'line-through text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-white'
                "
              >
                <svg
                  v-if="item.dot_ml"
                  v-tooltip="'Dimensions & Metrics are defined'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.0"
                  stroke="currentColor"
                  class="w-5 h-5 ml-1 stroke-green-500 dark:stroke-green-300 shrink-0"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
                {{ item.name ? item.name.split('.').slice(-1)[0] : '' }}
                <!-- show first part of the name without last slice -->
                <span class="text-xs ml-1 text-gray-400 dark:text-gray-500">
                  {{ item.name ? item.name.split('.').slice(0, -1).join('.') : '' }}
                </span>
              </th>
              <td
                class="pl-6 pr-2 py-4 text-xs max-w-2xl truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
              >
                {{ item.description || '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-wrapper">
        <!-- Help text -->
        <span class="text-sm text-gray-500 dark:text-gray-400">
          Showing
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ filteredTableItems.length > 0 ? (page - 1) * perPage + 1 : 0 }}
          </span>
          to
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ tablesShown }}
          </span>
          of
          <span class="font-semibold text-gray-600 dark:text-white">{{ allTables }}</span>
        </span>
        <div class="sm:ml-auto pagination-btn-wrapper">
          <!-- Buttons -->
          <button class="pagination-btn-prev" @click="page = Math.max(page - 1, 1)">
            <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
            Prev
          </button>
          <button
            class="pagination-btn-next"
            @click="page = Math.min(page + 1, Math.max(Math.ceil(items.length / perPage), 1))"
          >
            Next
            <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
          </button>
        </div>
      </div>
      <!-- Table for data end -->

      <div class="h-10" />

      <!-- Table for relationship start-->

      <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Relationships</h3>
      <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
        Define the relationships between your data sources. This will help Dot understand how your data is connected.
      </p>

      <div class="h-5" />

      <div class="flex items-center justify-between gap-4 flex-wrap">
        <Search
          :model-value="search_relationships"
          placeholder="Search..."
          @update:model-value="search_relationships = $event"
          @input="relationships_page = 1"
        />

        <div class="flex items-center gap-2">
          <button :disabled="loading_relationship_suggestion" class="gray-btn" @click="getRelationshipSuggestions">
            <Icon
              v-if="!loading_relationship_suggestion"
              icon="hugeicons:sparkles"
              class="w-4 h-4 text-[#262626] dark:text-white"
            />
            <LoadingIcon v-else class="!w-4 !h-4 text-gray-500 dark:text-gray-300 animate-spin" />

            <span>Suggest</span>
          </button>
          <button class="primary-btn" @click="addEmptyRelationship(drawerItem)">Add Relationship</button>
        </div>
      </div>

      <div class="table-wrapper">
        <table class="primary-table">
          <thead class="table-header">
            <tr>
              <th scope="col" class="px-3 py-3">Active</th>
              <th scope="col" class="px-3 py-3">Table</th>
              <th scope="col" class="px-3 py-3">Key(s)</th>
              <th scope="col" class="px-3 py-3">Type</th>
              <th scope="col" class="px-3 py-3">Other Table</th>
              <th scope="col" class="px-3 py-3">Other Key(s)</th>
              <th v-if="relationships.length" scope="col" class="px-3 py-3 opacity-0">Delete</th>
            </tr>
          </thead>
          <tbody v-if="relationships && relationships.length" class="table-body">
            <tr
              v-for="(relationship, index) in filteredRelationships"
              :key="relationship.relationship_id"
              v-tooltip="relationship.incoming ? `incoming relationship` : ''"
              class="bg-white dark:bg-gray-950"
              :class="{
                'rounded-bl-xl': index === relationships.length - 1,
              }"
            >
              <td class="w-4 p-2">
                <div class="flex items-center">
                  <input
                    id="checkbox-table-search-1"
                    v-model="relationship.active"
                    type="checkbox"
                    class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed"
                  />
                  <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                </div>
              </td>
              <td class="relative px-3 py-2 text-xs text-gray-900 whitespace-nowrap dark:text-white">
                <div :ref="`${index}-dropdownTable`">
                  <!-- Dropdown Button Table -->
                  <button
                    :id="`${index}-dropdownTableButton`"
                    class="inline-flex text-center items-center bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 w-64 p-2 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 truncate"
                    type="button"
                    @click="setDropdownPosition($event, 'table_keys', index)"
                  >
                    <span v-if="relationship.doc_id">
                      {{ items.find(x => x.id === relationship.doc_id)?.table_name }}
                      <span class="text-[#808080] dark:text-[#B0B0B0] pointer-events-none">
                        ({{ relationship.doc_id }})
                      </span>
                    </span>
                    <span v-else>Select Table</span>
                    <svg class="w-2.5 h-2.5 ml-auto" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>
                  <!-- Dropdown Menu -->
                  <div
                    :ref="`${index}-table_keys`"
                    v-click-away="() => $refs[`${index}-table_keys`][0].classList.add('hidden')"
                    class="fixed z-20 hidden bg-white rounded-xl shadow min-w-60 dark:bg-gray-800 w-max"
                    :style="{
                      top: `${dropdownPosition.top}px`,
                      left: `${dropdownPosition.left}px`,
                    }"
                  >
                    <div class="p-3 pb-0">
                      <Search
                        :model-value="search_table_dropdown"
                        placeholder="Search.."
                        @update:model-value="search_table_dropdown = $event"
                      />
                    </div>
                    <ul
                      class="h-48 px-3 py-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200"
                      aria-labelledby="dropdownTableButton"
                    >
                      <li v-for="table in getRelationsipTable()" :key="`${index}-table-li-${table.id}`">
                        <div class="flex items-center p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700">
                          <label
                            v-tooltip="table.table_name"
                            class="block w-max max-w-[300px] md:max-w-[500px] lg:max-w-[unset] py-1 cursor-pointer text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                            @click="
                              () => {
                                relationship.doc_id = table.id
                                $refs[`${index}-table_keys`][0].classList.toggle('hidden')
                              }
                            "
                          >
                            {{ table.table_name }}
                            <span class="text-[#808080] dark:text-[#B0B0B0]">({{ table.id }})</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="relative px-3 py-2 text-xs text-gray-900 whitespace-nowrap dark:text-white">
                <div :ref="`${index}-dropdownOwnColumns`">
                  <!-- Dropdown Button Own Columns -->
                  <button
                    :id="`${index}-dropdownOwnColumnsButton`"
                    class="inline-flex text-center items-center bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 w-64 p-2 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="button"
                    @click="setDropdownPosition($event, 'own_columns_keys', index)"
                  >
                    {{ relationship.own_columns ? relationship.own_columns.join(', ') : 'Select Columns' }}
                    <svg class="w-2.5 h-2.5 ml-auto" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>
                  <!-- Dropdown Menu -->
                  <div
                    :ref="`${index}-own_column_keys`"
                    v-click-away="() => $refs[`${index}-own_column_keys`][0].classList.add('hidden')"
                    class="fixed z-20 hidden bg-white rounded-xl shadow w-max min-w-60 dark:bg-gray-800"
                    :style="{
                      top: `${dropdownPosition.top}px`,
                      left: `${dropdownPosition.left}px`,
                    }"
                  >
                    <div class="p-1 pb-0">
                      <Search
                        :model-value="search_own_columns"
                        placeholder="Search.."
                        class="!p-2"
                        @update:model-value="search_own_columns = $event"
                      />
                    </div>
                    <ul
                      class="h-48 px-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200"
                      aria-labelledby="dropdownOwnColumnsButton"
                    >
                      <li
                        v-for="column in getRelationshipTableColumns(relationship)"
                        :key="`${index}-own-li-${column.column_name}`"
                      >
                        <div
                          class="flex items-center p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 min-w-full w-max max-w-[300px] md:max-w-[500px] lg:max-w-[unset]"
                        >
                          <input
                            :id="`${index}-own-checkbox-item-${column.column_name}`"
                            type="checkbox"
                            :checked="relationship.own_columns.includes(column.column_name)"
                            class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            @change="
                              relationship.own_columns.includes(column.column_name)
                                ? relationship.own_columns.splice(
                                    relationship.own_columns.indexOf(column.column_name),
                                    1
                                  )
                                : relationship.own_columns.push(column.column_name)
                            "
                            @click="$refs[`${index}-own_column_keys`][0].classList.toggle('hidden')"
                          />
                          <label
                            :for="`${index}-own-checkbox-item-${column.column_name}`"
                            class="w-full p-1 cursor-pointer ms-2 text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                          >
                            {{ column.column_name }}
                            <span class="text-[#808080] dark:text-[#B0B0B0] font-mono">
                              {{ isJson(column.data_type) ? JSON.parse(column.data_type).type : column.data_type }}
                            </span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="relative px-3 py-2">
                <svg
                  v-if="relationship.incoming && relationship.type === 'foreign'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                  />
                </svg>
                <svg
                  v-else-if="relationship.incoming && relationship.type === 'shared'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                </svg>
                <div v-else class="flex flex-row items-center">
                  <!-- Inline SVG for "foreign" option -->
                  <svg
                    v-if="relationship.type === 'foreign'"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                  </svg>

                  <!-- Inline SVG for "shared" option -->
                  <svg
                    v-else-if="relationship.type === 'shared'"
                    :disabled="items.find(x => x.id === relationship.doc_id)?.org_id !== org.id"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                  </svg>

                  <button
                    v-if="items.find(x => x.id === relationship.doc_id)?.org_id === org.id"
                    class="ml-2 inline-flex text-center items-center bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 w-64 p-2 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="button"
                    @click="setDropdownPosition($event, 'join_type', index)"
                  >
                    {{ relationship.type === 'foreign' ? 'Foreign Key' : 'Shared Dimensions' }}
                    <svg class="w-2.5 h-2.5 ml-auto" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  <!-- Dropdown Menu -->
                  <div
                    :ref="`${index}-join_type`"
                    v-click-away="() => $refs[`${index}-join_type`][0]?.classList?.add('hidden')"
                    class="fixed z-30 hidden bg-white rounded-xl shadow w-60 dark:bg-gray-800 right-[100px]"
                    :style="{
                      top: `${dropdownPosition.top}px`,
                      left: `${dropdownPosition.left}px`,
                    }"
                  >
                    <ul
                      class="h-48 px-3 py-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200"
                      aria-labelledby="dropdownSearchButton"
                    >
                      <li v-for="type in ['foreign', 'shared']" :key="type">
                        <div class="flex items-center p-1 w-full rounded hover:bg-gray-100 dark:hover:bg-gray-700">
                          <input
                            :id="`${index}-checkbox-item-${type}`"
                            type="radio"
                            :checked="relationship.type === type"
                            class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            @change="relationship.type = type"
                            @click="$refs[`${index}-join_type`][0].classList.toggle('hidden')"
                          />
                          <label
                            :for="`${index}-checkbox-item-${type}`"
                            class="w-full p-1 cursor-pointer ms-2 text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                          >
                            {{ type === 'foreign' ? 'Foreign Key' : 'Shared Dimensions' }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>

              <td class="px-3 py-2 text-xs text-gray-900 whitespace-nowrap dark:text-white">
                <!-- Dropdown Button Referenced Tables -->
                <div :ref="`${index}-dropdownReferencedTables`">
                  <button
                    v-if="relationship.doc_id"
                    class="w-full inline-flex text-center items-center bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 max-w-64 p-2 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="button"
                    @click="setDropdownPosition($event, 'referenced_tables', index)"
                  >
                    {{ relationship.table ? items.find(x => x.id === relationship.table)?.table_name : 'Select Table' }}
                    <svg class="w-2.5 h-2.5 ml-auto" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  <!-- Dropdown Menu -->
                  <div
                    v-if="items && relationship.doc_id && items.filter(x => x.id === relationship.doc_id).length"
                    :ref="`${index}-referenced_tables`"
                    v-click-away="() => $refs[`${index}-referenced_tables`][0]?.classList?.add('hidden')"
                    class="fixed z-30 hidden bg-white rounded-xl shadow w-max min-w-60 dark:bg-gray-800 right-[100px]"
                    :style="{
                      top: `${dropdownPosition.top}px`,
                      left: `${dropdownPosition.left}px`,
                    }"
                  >
                    <div class="p-3 pb-0">
                      <Search
                        :model-value="search_referenced_tables"
                        placeholder="Search.."
                        @update:model-value="search_referenced_tables = $event"
                      />
                    </div>
                    <ul
                      class="h-48 px-3 py-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200"
                      aria-labelledby="dropdownSearchButton"
                    >
                      <li v-for="table in getReferencedRelationshipTables(relationship)" :key="table.id">
                        <div
                          class="flex items-center p-1 min-w-full rounded hover:bg-gray-100 dark:hover:bg-gray-700 w-max max-w-[300px] md:max-w-[500px] lg:max-w-[unset]"
                        >
                          <input
                            :id="`${index}-checkbox-item-${table.table_name}`"
                            type="checkbox"
                            :checked="relationship.table === table.id"
                            class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            :disabled="items.find(x => x.id === relationship.doc_id)?.org_id !== org.id"
                            @change="relationship.table = table.id"
                            @click="$refs[`${index}-referenced_tables`][0].classList.toggle('hidden')"
                          />
                          <label
                            :for="`${index}-checkbox-item-${table.table_name}`"
                            class="w-full p-1 cursor-pointer ms-2 text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                          >
                            {{ table.table_name }}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="relative px-3 py-2 text-xs text-gray-900 whitespace-nowrap dark:text-white">
                <div :ref="`${index}-dropdownColumnsKeys`">
                  <!-- Dropdown Button Referenced Columns -->
                  <button
                    v-if="relationship.table"
                    class="w-full inline-flex text-center items-center bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 max-w-64 p-2 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    type="button"
                    @click="setDropdownPosition($event, 'column_keys', index)"
                  >
                    {{ relationship.columns ? relationship.columns.join(', ') : 'Select Columns' }}
                    <svg class="w-2.5 h-2.5 ml-auto" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  <!-- Dropdown Menu -->
                  <div
                    v-if="items && relationship.table && items.filter(x => x.id === relationship.table).length"
                    :ref="`${index}-column_keys`"
                    v-click-away="() => $refs[`${index}-column_keys`][0]?.classList?.add('hidden')"
                    class="fixed z-30 hidden bg-white rounded-xl shadow w-max min-w-60 dark:bg-gray-800 right-[100px]"
                    :style="{
                      top: `${dropdownPosition.top}px`,
                      left: `${dropdownPosition.left}px`,
                    }"
                  >
                    <div class="p-3 pb-0">
                      <Search
                        :model-value="search_referenced_columns"
                        placeholder="Search.."
                        @update:model-value="search_referenced_columns = $event"
                      />
                    </div>
                    <ul
                      class="h-48 px-3 py-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200"
                      aria-labelledby="dropdownSearchButton"
                    >
                      <li
                        v-for="column in getReferencedRelationshipTableColumns(relationship)"
                        :key="column.column_name"
                      >
                        <div
                          class="flex items-center p-1 min-w-full rounded hover:bg-gray-100 dark:hover:bg-gray-700 w-max max-w-[300px] md:max-w-[500px] lg:max-w-[unset]"
                        >
                          <input
                            :id="`${index}-checkbox-item-${column.column_name}`"
                            type="checkbox"
                            :checked="relationship.columns.includes(column.column_name)"
                            class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                            @change="
                              relationship.columns.includes(column.column_name)
                                ? relationship.columns.splice(relationship.columns.indexOf(column.column_name), 1)
                                : relationship.columns.push(column.column_name)
                            "
                            @click="$refs[`${index}-column_keys`][0].classList.toggle('hidden')"
                          />
                          <label
                            :for="`${index}-checkbox-item-${column.column_name}`"
                            class="w-full p-1 cursor-pointer ms-2 text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                          >
                            {{ column.column_name }}
                            <span class="text-[#808080] dark:text-[#B0B0B0] font-mono">
                              {{ isJson(column.data_type) ? JSON.parse(column.data_type).type : column.data_type }}
                            </span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
              <td class="px-2 py-2">
                <button
                  v-if="
                    items.filter(x => x.id === relationship.doc_id).length &&
                    items.filter(x => x.id === relationship.doc_id)[0].org_id === org.id
                  "
                  type="button"
                  class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  @click="relationships.splice(index, 1)"
                >
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="sr-only">Delete Relationship</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-wrapper">
        <!-- Help text -->
        <span class="text-sm text-gray-500 dark:text-gray-400">
          Showing
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ filteredRelationships.length > 0 ? (relationships_page - 1) * relationshipsRowsPerPage + 1 : 0 }}
          </span>
          to
          <span class="font-semibold text-gray-600 dark:text-white">
            {{ relationshipsShown }}
          </span>
          of
          <span class="font-semibold text-gray-600 dark:text-white">{{ allRelationships }}</span>
        </span>
        <div class="sm:ml-auto pagination-btn-wrapper">
          <!-- Buttons -->
          <button
            class="pagination-btn-prev"
            :disabled="relationships_page <= 1"
            @click="relationships_page = Math.max(relationships_page - 1, 1)"
          >
            <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
            Prev
          </button>
          <button
            class="pagination-btn-next"
            :disabled="relationships_page >= Math.ceil(relationships.length / relationshipsRowsPerPage)"
            @click="
              relationships_page = Math.min(
                relationships_page + 1,
                Math.max(Math.ceil(relationships.length / relationshipsRowsPerPage), 1)
              )
            "
          >
            Next
            <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
          </button>
        </div>
      </div>

      <div class="h-5" />

      <h4>Foreign Keys</h4>
      <p class="text-sm text-gray-500 dark:text-gray-400 text-left pb-2">
        Foreign keys can be used to join tables with one-to-many relationships.
        <br />
        E.g. orders and customers might be joined by customer_id to compare revenue by customer.
        <br />
        <b>
          If you are not sure, only connect a foreign key from a fact table (e.g. customer_id in orders table) → to a
          primary key in a dimension table (e.g. id in customers table).
        </b>
        <br />
        <a
          class="text-blue-600 dark:text-blue-300 hover:underline"
          href="https://docs.getdot.ai/dot/whats-dot/model#define-relationships-joins"
          target="_blank"
        >
          Learn more.
        </a>
      </p>

      <h4>Shared Dimensions</h4>
      <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
        Shared dimensions can be used to combine tables with many-to-many relationships.
        <br />
        E.g. orders and pageviews might be joined by date and country_id to compare revenue and pageviews by country
        over time.
        <br />
        Dot will automatically aggregate by shared dimensions to prevent a join-fanout.
      </p>
      <div class="h-10" />

      <!-- Table for relationship end -->

      <!-- drawer init and toggle -->
      <div class="text-center">
        <button
          ref="sideDrawerButton"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          type="button"
          style="visibility: hidden"
          data-drawer-backdrop="false"
          data-drawer-target="drawer-right-example"
          data-drawer-show="drawer-right-example"
          data-drawer-placement="right"
          aria-controls="drawer-right-example"
        >
          Show right drawer
        </button>
      </div>

      <!-- drawer component for tables-->
      <div
        id="drawer-right-example"
        class="fixed top-0 right-0 z-[80] h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-full max-w-[1600px] dark:bg-gray-950 shadow"
        tabindex="-1"
        aria-labelledby="drawer-right-label"
      >
        <div class="flex flex-row items-center">
          <h3 id="drawer-right-label" class="text-lg font-bold text-left px-1">Configure Data Source</h3>
          <span class="flex-1" />
          <button
            v-if="drawerItem?.org_id == org.id && isDrawerItemBlockedByCurrentUser"
            v-tooltip="'Suggest data source + column descriptions'"
            :disabled="loading_suggestion"
            class="gray-btn"
            @click="suggestDescriptions()"
          >
            <Icon v-if="!loading_suggestion" icon="hugeicons:sparkles" class="w-4 h-4 text-[#262626] dark:text-white" />
            <LoadingIcon v-else class="!w-4 !h-4 text-gray-500 dark:text-gray-300 animate-spin" />
            <span>Suggest</span>
          </button>
          <button
            v-if="drawerItem?.org_id == org.id && isDrawerItemBlockedByCurrentUser"
            v-tooltip="save_enabled ? 'Save changes' : 'No changes to save'"
            :class="save_enabled ? 'primary-btn' : 'primary-btn bg-gray-500 dark:bg-gray-600'"
            :disabled="!save_enabled"
            class="ml-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white rounded-xl hover:bg-blue-800 disabled:hover:bg-gray-500 focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 dark:disabled:hover:bg-gray-600 focus:outline-none dark:focus:ring-blue-800"
            @click="saveItem()"
          >
            <span v-if="!loading_save">Save</span>
            <span v-else>Loading...</span>

            <svg
              v-if="!loading_save"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              :class="save_enabled ? 'animate-pulse' : ''"
              class="ml-2 w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-width="2"
                stroke-linejoin="round"
                d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              v-else
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 ml-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            v-if="drawerItem?.org_id == org.id && !isDrawerItemBlockedByCurrentUser"
            v-tooltip="'Forcefully unblocking this element might remove changes made by the other user.'"
            class="ml-2 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-xl hover:bg-red-700 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-800"
            @click="takeOverTable(drawerItem?.id)"
          >
            <Icon icon="mdi:account-cancel" class="w-4 h-4 mr-2" />
            <div class="flex flex-col text-left">
              <span>Take Over</span>
              <span v-if="drawerItemBlockedBy?.user_id !== userStore.user.id" class="text-xs">
                Blocked by User {{ drawerItemBlockedBy?.user_id }} since
                {{ formatDistanceToNow(parseISO(drawerItemBlockedBy?.timestamp), { addSuffix: true }) }}
              </span>
              <span v-else class="text-xs">
                Blocked by yourself in another window since
                {{ formatDistanceToNow(parseISO(drawerItemBlockedBy?.timestamp), { addSuffix: true }) }}
              </span>
            </div>
          </button>
          <button
            v-show="!save_enabled && !changes_made"
            ref="closeButtonTable"
            type="button"
            data-drawer-hide="drawer-right-example"
            aria-controls="drawer-right-example"
            class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="handleCloseTable()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close menu</span>
          </button>
          <button
            v-show="save_enabled || changes_made"
            type="button"
            class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="handleCloseTable()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close menu</span>
          </button>
        </div>

        <p class="text-xs mb-6 text-gray-500 dark:text-gray-400 text-left px-1">
          Describe what each record represents and select all fields Dot should know about.
          <br />
          Click "Suggest" to generate descriptions and fetch samples for selected fields.
        </p>

        <p v-if="drawerItem.archived" class="text-sm mb-2 text-red-500 dark:text-red-400 text-left px-2">
          This data source has been archived because it was active but not found by Dot during the last sync.
        </p>

        <!-- button to delete data source -->
        <button
          v-if="drawerItem.archived"
          class="ml-2 bg-red-700 dark:bg-red-600 text-white text-sm mb-2 font-medium rounded-xl px-4 py-2 inline-flex items-center hover:bg-red-800 focus:ring-4 focus:ring-red-300 focus:outline-none dark:focus:ring-red-800"
          @click="deleteDataSource(drawerItem.id)"
        >
          Delete Permanently
        </button>

        <!-- show warning in red if it is set -->
        <p v-if="drawerItem.warning" class="text-sm mb-2 text-red-500 dark:text-red-400 text-left px-2">
          {{ drawerItem.warning }}
        </p>

        <p class="mb-2 text-sm medium text-gray-900 dark:text-white ml-2 font-mono">
          {{ drawerItem.id }}
          <span
            v-if="drawerItem.num_rows"
            v-tooltip="drawerItem.num_rows"
            class="text-xs text-gray-500 dark:text-gray-400"
          >
            · {{ millify(drawerItem.num_rows) }} rows
          </span>
        </p>

        <ResizableTextarea
          :min-rows="3"
          :model-value="drawerItem.description"
          placeholder="Each row represents an order ..."
          :disabled="drawerItem.org_id !== org.id"
          :loading="loading_suggestion"
          @update:model-value="drawerItem.description = $event"
        />

        <!-- for looker show a toggle if always filters should be enforced -->
        <div
          v-if="drawerItem.database_name === 'Looker' && drawerItem.columns.some(col => col.always_filter)"
          class="w-full flex items-center align-middle gap-1 mt-1"
        >
          <ToggleButton
            class="mr-2"
            :active="drawerItem.always_filters_enforced"
            @update:active="$event => (drawerItem.always_filters_enforced = $event)"
          />
          <p>
            Enforce Always Filters
            <br />
            <span
              v-for="(col, index) in drawerItem.columns.filter(col => col.always_filter)"
              :key="index"
              class="font-mono text-[#A0A0A0] dark:text-[#707070] text-xs"
            >
              {{ col.column_name }}: {{ col.always_filter }}
              <span v-if="index < drawerItem.columns.filter(col => col.always_filter).length - 1">·</span>
            </span>
          </p>
        </div>

        <!-- tabs -->

        <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul
            id="myTab"
            class="flex flex-wrap -mb-px text-sm text-center font-bold text-gray-900 dark:text-white"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li class="mr-2" role="presentation">
              <button
                id="column-tab"
                class="inline-flex items-center p-4 border-b-2 rounded-t-lg"
                data-tabs-target="#column_pane"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Fields
                <span class="opacity-50">&nbsp;·&nbsp;</span>
                <span class="text-xs font-semibold opacity-70">
                  {{ drawerItem?.columns.filter(x => x.active)?.length }}
                </span>
              </button>
            </li>
            <li class="mr-2" role="presentation">
              <button
                id="groups-tab"
                class="inline-flex items-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                data-tabs-target="#groups_pane"
                type="button"
                role="tab"
                aria-controls="groups_pane"
                aria-selected="false"
              >
                Access
                <span class="opacity-50">&nbsp;·&nbsp;</span>
                <span class="text-xs font-semibold opacity-70">{{ drawerItem?.groups?.length }}</span>
              </button>
            </li>
            <li class="mr-2" role="presentation">
              <button
                id="queries-tab"
                class="inline-flex items-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                data-tabs-target="#queries_pane"
                type="button"
                role="tab"
                aria-controls="queries_pane"
                aria-selected="false"
              >
                Queries
                <span class="opacity-50">&nbsp;·&nbsp;</span>
                <span class="text-xs font-semibold opacity-70">{{ drawerItem?.queries?.length }}</span>
              </button>
            </li>
            <li class="mr-2" role="presentation" :class="drawerItem?.note_dicts?.length ? '' : 'hidden'">
              <button
                id="notes-tab"
                class="inline-flex items-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                data-tabs-target="#notes_pane"
                type="button"
                role="tab"
                aria-controls="notes_pane"
                aria-selected="false"
              >
                Notes
                <span class="opacity-50">&nbsp;·&nbsp;</span>
                <span class="text-xs font-semibold opacity-70">{{ drawerItem?.note_dicts?.length }}</span>
              </button>
            </li>
          </ul>
        </div>
        <div id="myTabContent">
          <div id="column_pane" class="hidden" role="tabpanel" aria-labelledby="column-tab">
            <!-- Column Table for data start -->
            <div class="flex items-center">
              <Search
                :model-value="search_drawer"
                placeholder="Search.."
                @update:model-value="search_drawer = $event"
                @input="page_drawer = 1"
              />
              <div class="ml-auto flex items-center p-2 text-gray-400 dark:text-gray-500 cursor-pointer text-xs">
                <span
                  v-tooltip="'sort by position'"
                  :class="column_sort_attribute === 'position' ? 'text-blue-600 dark:text-blue-300' : ''"
                  class="hover:underline"
                  @click="sort_columns_by_attr('position')"
                >
                  POSITION
                </span>
                &nbsp;·&nbsp;
                <span
                  v-tooltip="'sort by usage'"
                  :class="column_sort_attribute === 'usage' ? 'text-blue-600 dark:text-blue-300' : ''"
                  class="hover:underline"
                  @click="sort_columns_by_attr('usage')"
                >
                  USAGE
                </span>
                &nbsp;·&nbsp;
                <span
                  v-tooltip="'sort by name'"
                  :class="column_sort_attribute === 'name' ? 'text-blue-600 dark:text-blue-300' : ''"
                  class="hover:underline"
                  @click="sort_columns_by_attr('name')"
                >
                  NAME
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2.0"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                </svg>
              </div>
            </div>
            <div class="table-wrapper">
              <table class="primary-table">
                <thead class="table-header">
                  <tr>
                    <th scope="col" class="p-2">
                      <div class="flex items-center">
                        <input
                          id="checkbox-table-all"
                          type="checkbox"
                          :checked="filteredColumnItems_drawer.map(item => item.active).reduce((a, b) => a && b, true)"
                          :disabled="drawerItem.org_id !== org.id"
                          class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          @click="toggleAll_drawer"
                        />
                        <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                      </div>
                    </th>
                    <th scope="col" class="px-6 py-3">
                      <div class="flex items-center">Name</div>
                    </th>
                    <th scope="col" class="px-3 py-3">Description</th>
                    <th scope="col" class="px-3 py-3">Sample</th>
                  </tr>
                </thead>
                <tbody class="table-body">
                  <tr
                    v-for="(item_drawer, index) in filteredColumnItems_drawer"
                    :key="item_drawer.id"
                    class="bg-white dark:bg-gray-950 hover:bg-gray-50 dark:hover:bg-gray-900"
                    :class="{
                      'rounded-bl-xl': index === filteredColumnItems_drawer.length - 1,
                    }"
                  >
                    <td class="w-4 px-2 py-1">
                      <div class="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          :checked="item_drawer.active"
                          type="checkbox"
                          :disabled="drawerItem.org_id !== org.id"
                          class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          @click.stop="$event => handleDrawerCheckboxToggle(index, item_drawer, $event)"
                        />
                        <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                      </div>
                    </td>
                    <td
                      v-tooltip="
                        item_drawer.column_name +
                        ' ' +
                        (item_drawer.usage === 0
                          ? 'is not used'
                          : item_drawer.usage > 0
                            ? 'was used ' + item_drawer.usage + ' times in the last weeks'
                            : '')
                      "
                      scope="row"
                      dir="rtl"
                      class="pl-6 pr-2 py-1 text-xs max-w-sm h-full truncate font-medium whitespace-nowrap font-mono"
                      :class="
                        item_drawer.usage === 0 ? 'text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-white'
                      "
                    >
                      <span>{{ item_drawer.column_name }}</span>
                      <span
                        :class="
                          item_drawer.agg_type && item_drawer.agg_type !== 'dimension'
                            ? ' text-[#ffa833]  dark:text-orange'
                            : 'text-gray-400 dark:text-gray-500'
                        "
                      >
                        <!-- Indication for primary key, partition column, or unique -->
                        <span
                          v-if="item_drawer.primary_key || item_drawer.is_partition_column"
                          class="text-[#ffa833] dark:text-orange ml-2"
                        >
                          {{
                            item_drawer.primary_key
                              ? 'PRIMARY KEY'
                              : item_drawer.is_partition_column
                                ? 'PARTITION'
                                : item_drawer.unique
                                  ? 'UNIQUE'
                                  : ''
                          }}
                        </span>
                        {{
                          isJson(item_drawer.data_type) ? JSON.parse(item_drawer.data_type).type : item_drawer.data_type
                        }}
                      </span>
                    </td>
                    <td class="px-2 pt-2 pb-1 w-1/2">
                      <ResizableTextarea
                        :min-rows="1"
                        :model-value="item_drawer.comment"
                        placeholder="Value of row"
                        :disabled="drawerItem.org_id !== org.id"
                        :loading="loading_suggestion"
                        class="!text-xs"
                        thumb-class="!w-5"
                        @update:model-value="item_drawer.comment = $event"
                      />
                    </td>
                    <td class="px-2 pt-2 pb-1 w-1/3">
                      <ResizableTextarea
                        :min-rows="1"
                        :model-value="item_drawer.sample"
                        placeholder="Value of row"
                        :disabled="drawerItem.org_id !== org.id"
                        :loading="loading_suggestion && drawerItem.request_id !== item_drawer.request_id"
                        :has-error="hasError(item_drawer.sample)"
                        class="!text-xs font-mono"
                        thumb-class="!w-5"
                        @update:model-value="item_drawer.sample = $event"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination-wrapper">
              <!-- Help text -->
              <div class="text-sm flex items-center gap-1">
                <span class="text-gray-500 dark:text-gray-400 flex items-center gap-1">
                  Showing
                  <span class="font-semibold text-gray-600 dark:text-white">
                    {{ filteredColumnItems_drawer.length > 0 ? (page_drawer - 1) * perPage_drawer + 1 : 0 }}
                  </span>
                  to
                  <span class="font-semibold text-gray-600 dark:text-white">
                    {{ drawerColumnsShown }}
                  </span>
                  of
                  <span class="font-semibold text-gray-600 dark:text-white">
                    {{ allDrawerColumns }}
                  </span>
                </span>
                <span class="text-gray-500 dark:text-gray-400 w-max shrink-0">· Rows per page</span>
                <select
                  id="perPage_draawer"
                  v-model="perPage_drawer"
                  class="ml-2 cursor-pointer max-w-[80px] bg-gray-50 border border-gray-200 text-gray-800 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @change="page_drawer = 1"
                >
                  <option :value="10" selected>10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                  <option :value="500">500</option>
                </select>
              </div>

              <div class="sm:ml-auto pagination-btn-wrapper">
                <!-- Buttons -->
                <button class="pagination-btn-prev" @click="page_drawer = Math.max(page_drawer - 1, 1)">
                  <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
                  Prev
                </button>
                <button
                  class="pagination-btn-next"
                  @click="
                    page_drawer = Math.min(
                      page_drawer + 1,
                      Math.max(Math.ceil(drawerItem.columns.length / perPage_drawer), 1)
                    )
                  "
                >
                  Next
                  <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
                </button>
              </div>
            </div>
            <!-- Table for data end -->
            <p class="text-xs my-2 text-gray-500 dark:text-gray-400">
              Estimated Size: {{ drawer_token_size }}
              <span v-if="drawer_token_size > 50000" class="text-orange">⚠️ Size should be less than 50000.</span>
            </p>
          </div>
          <div id="groups_pane" class="hidden" role="tabpanel" aria-labelledby="groups-tab-tab">
            <div
              v-if="drawerItem.groups"
              class="p-1.5 pr-5 relative w-full border border-gray-200 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white focus-within:ring-blue-500 focus-within:border-blue-500 dark:focus-within:ring-blue-500 dark:focus-within:border-blue-500 rounded-full overflow-hidden"
            >
              <div class="flex items-center flex-row flex-wrap gap-1">
                <span
                  v-for="(group, index) in drawerItem.groups"
                  :key="index"
                  v-tooltip="`Remove ${group} from ${drawerItem.name}`"
                  class="py-1 px-3 font-mono font-medium text-xs text-white bg-gray-950 hover:bg-gray-600 dark:text-dark-text dark:bg-white dark:hover:bg-gray-400 rounded-full flex items-center gap-1 w-max cursor-pointer"
                  @click="removeTableFromGroup(drawerItem, group)"
                >
                  {{ group }}
                  <CloseIcon class="w-[14px]" />
                </span>
                <div class="grow">
                  <input
                    id="inviteMembers"
                    v-model="textInput"
                    :placeholder="drawerItem.groups.length ? 'Add another group' : 'Add data source to group'"
                    autocomplete="off"
                    class="block w-full font-mono font-medium text-xs border-0 outline-none p-0 pl-1 py-2 focus:ring-0 text-gray-900 dark:text-white bg-transparent"
                    type="text"
                    @keydown.tab.stop.prevent="addAutocomplete(drawerItem, $event, $event.target.value)"
                    @keyup="(showSuggestion(drawerItem), addTableToGroup(drawerItem, $event, $event.target.value))"
                  />
                  <div v-if="autoCompPlaceholder" class="clearit virtual-text">
                    <div class="hidden-text text-xs font-mono font-medium">
                      {{ textInput }}
                    </div>
                    <input
                      :placeholder="autoCompPlaceholder"
                      class="autocomplete text-xs font-mono font-medium"
                      type="text"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Row level permissions -->
            <div class="h-5" />
            <label class="inline-flex items-center cursor-pointer px-3">
              <input v-model="drawerItem.row_level_permissions_on" type="checkbox" class="sr-only peer" />
              <div
                class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
              />
              <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Row Level Permissions</span>
            </label>
            <div v-if="drawerItem.row_level_permissions_on" class="mt-2">
              <p class="text-sm mb-4 text-gray-500 dark:text-gray-400 text-left px-3">
                Row level permissions enable to prefilter the records in a table depending on the first group that the
                user has assigned.
                <br />
                E.g. a user could have the groups
                <code>['germany', 'all_users']</code>
                .
                <br />
                Dot will replace the
                <code>${groupname}</code>
                placeholder in the where clause template with
                <code>germany</code>
                .
                <br />
                <br />
                The template expression needs to start with WHERE and be a valid SQL expression.
                <br />
                Like that you have the flexibility of SQL to define a row level access policy.
                <br />

                <br />
                <br />
                Example 1: Check if groupname is in column_id
                <code>WHERE '${groupname}' = column_id</code>
                <br />
                <br />
                Example 2: Map groupname to a country code
                <code>
                  WHERE CASE '${groupname}' WHEN 'germany' THEN 'DE' WHEN 'france' THEN 'FR' END = country_code
                </code>
                <br />
                <br />
                To insert all groups of a user, use the placeholder
                <code>${all_groupnames}</code>
                , e.g. like that:
                <br />
                <code>WHERE country_code IN (${all_groupnames})</code>
                <br />
                In this case a user can have simultaneously access to multiple countries. The placeholder will be
                replaced by a comma separated list of groupnames in single quotes, e.g.:
                <code>'germany', 'france'</code>
              </p>

              <label for="message" class="block mx-3 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Where Clause Template
              </label>
              <ResizableTextarea
                :min-rows="2"
                :model-value="drawerItem.row_level_permissions_expression"
                placeholder="WHERE '${groupname}' = column_id"
                :disabled="drawerItem.org_id !== org.id"
                :loading="loading_suggestion"
                @update:model-value="drawerItem.row_level_permissions_expression = $event"
              />
            </div>
          </div>
          <div id="queries_pane" class="hidden" role="tabpanel" aria-labelledby="queries-tab">
            <!-- Table for queries start -->
            <div class="flex flex-col sm:flex-row sm:justify-between">
              <Search
                :model-value="search_queries"
                placeholder="Search.."
                @update:model-value="search_queries = $event"
                @input="page_queries = 1"
              />

              <!-- create button "new query"-->
              <button
                class="gray-btn"
                @click.prevent="
                  () => {
                    $refs.sideDrawerButtonQuery.click()
                    drawerItemQuery = {
                      question_text: '',
                      sql_text: '',
                      tests_yaml: test_placeholder,
                      table_id: drawerItem.id,
                    }
                    backdropIsVisibleQuery = true
                  }
                "
              >
                + New Question
              </button>
            </div>
            <div class="table-wrapper">
              <table class="primary-table">
                <thead class="table-header">
                  <tr>
                    <th scope="col" class="p-4">
                      <div class="flex items-center">Active</div>
                    </th>
                    <th scope="col" class="px-6 py-3">
                      <div class="flex items-center">Question</div>
                    </th>
                    <th scope="col" class="px-6 py-3">Query</th>
                    <th class="px-3 py-3" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in filteredQueries"
                    :key="item.id"
                    class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 cursor-pointer"
                    @click="
                      ($refs.sideDrawerButtonQuery.click(), (drawerItemQuery = item), (backdropIsVisibleQuery = true))
                    "
                  >
                    <td class="w-4 p-2">
                      <div class="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          v-model="item.active"
                          type="checkbox"
                          class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed"
                          @click.stop="$event => toggleQueryStatus($event, item)"
                        />
                        <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                      </div>
                    </td>
                    <th
                      scope="row"
                      alt="item.name"
                      class="pl-6 pr-2 py-4 text-xs max-w-xl block truncate font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ item.question_text }}
                    </th>
                    <td
                      class="pl-6 pr-2 py-4 text-xs max-w-sm font-mono truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
                    >
                      {{ item.sql_text }}
                    </td>
                    <td>
                      <button
                        v-if="item.org_id == org.id"
                        type="button"
                        class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        @click.stop="deleteQuery(item)"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="sr-only">delete query</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pagination-wrapper">
              <!-- Help text -->
              <span class="text-sm text-gray-500 dark:text-gray-400">
                Showing
                <span class="font-semibold text-gray-600 dark:text-white">
                  {{ drawerItem.queries?.length > 0 ? (page_queries - 1) * perPage_queries + 1 : 0 }}
                </span>
                to
                <span class="font-semibold text-gray-600 dark:text-white">
                  {{ queriesShown }}
                </span>
                of
                <span class="font-semibold text-gray-600 dark:text-white">{{ allQueries }}</span>
                Questions
              </span>

              <div class="sm:ml-auto pagination-btn-wrapper">
                <!-- Buttons -->
                <button class="pagination-btn-prev" @click="page_queries = Math.max(page_queries - 1, 1)">
                  <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
                  Prev
                </button>
                <button
                  class="pagination-btn-next"
                  @click="
                    page_queries = Math.min(
                      page_queries + 1,
                      Math.max(Math.ceil(drawerItem.queries?.length / perPage_queries), 1)
                    )
                  "
                >
                  Next
                  <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
                </button>
              </div>
            </div>
            <!-- Table for queries end -->
          </div>

          <!-- notes tab -->
          <div id="notes_pane" class="hidden" role="tabpanel" aria-labelledby="notes-tab">
            <!-- Table for notes start -->
            <div class="flex flex-col sm:flex-row sm:justify-between">
              <Search
                :model-value="search_notes"
                placeholder="Search.."
                @update:model-value="search_notes = $event"
                @input="page_notes = 1"
              />
            </div>
            <div class="table-wrapper">
              <table class="primary-table">
                <thead class="table-header">
                  <tr>
                    <th scope="col" class="p-2 pl-4">Active</th>
                    <th scope="col" class="px-6 py-3">
                      <div class="flex items-center">Note</div>
                    </th>
                    <th scope="col" class="p-2 pl-4">Created</th>
                    <th scope="col" class="px-6 py-3" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in filteredNotes"
                    :key="item.note"
                    class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900"
                  >
                    <td class="w-4 p-2">
                      <div class="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          v-model="item.active"
                          type="checkbox"
                          class="w-5 h-5 mx-auto text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 disabled:cursor-not-allowed"
                          @click.stop="item.active = !item.active"
                        />
                        <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                      </div>
                    </td>
                    <th
                      scope="row"
                      alt="item.name"
                      class="pl-6 pr-2 py-4 text-xs w-full block truncate font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {{ item.note }}
                    </th>
                    <!-- timestamp iwth formatDistanceToNow from item.created_at -->
                    <td class="px-2 py-2">
                      <span class="text-xs text-gray-500 dark:text-gray-400">
                        {{ formatDistanceToNow(new Date(item.created_at), { addSuffix: true }) }}
                      </span>
                    </td>
                    <!-- delete Note. Can be quite simple in just removing the the item from drawerItems.note_dicts -->
                    <td>
                      <button
                        type="button"
                        class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        @click.stop="drawerItem.note_dicts.splice(drawerItem.note_dicts.indexOf(item), 1)"
                      >
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <span class="sr-only">delete note</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Table for notes end -->
          </div>
          <!-- end notes tab -->
        </div>

        <!-- end tabs -->

        <div class="h-20" />
      </div>

      <!-- end side drawer for tables -->

      <!-- side drawer for queries -->

      <!-- query drawer init and toggle -->
      <div class="text-center">
        <button
          ref="sideDrawerButtonQuery"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          type="button"
          style="visibility: hidden"
          data-drawer-backdrop="false"
          data-drawer-target="drawer-right-query"
          data-drawer-show="drawer-right-query"
          data-drawer-placement="right"
          aria-controls="drawer-right-query"
        >
          Show right query drawer
        </button>
      </div>

      <!-- query drawer component -->
      <div
        id="drawer-right-query"
        class="fixed top-0 right-0 z-[91] h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-[1000px] dark:bg-gray-950"
        tabindex="-1"
        aria-labelledby="drawer-right-label"
      >
        <div class="flex flex-row items-center">
          <h3 id="drawer-right-label" class="text-lg font-bold text-left">Configure Question</h3>
          <span class="flex-1" />

          <button
            v-if="false && (!drawerItemQuery.org_id || drawerItemQuery.org_id == org.id)"
            class="ml-auto inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-950 rounded-xl hover:bg-gray-100 dark:hover:bg-gray-900 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
            @click="validateQuery()"
          >
            <span v-if="!loading_suggestion">Validate</span>
            <span v-else>Loading...</span>

            <svg
              v-if="!loading_suggestion"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="ml-2 w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>
            <svg
              v-else
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 ml-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            v-if="!drawerItemQuery.org_id || drawerItemQuery.org_id == org.id"
            class="ml-4 inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-xl hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            @click="saveQuery()"
          >
            <span v-if="!loading_save">Save</span>
            <span v-else>Loading...</span>

            <svg
              v-if="!loading_save"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="ml-2 w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-width="2"
                stroke-linejoin="round"
                d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <svg
              v-else
              aria-hidden="true"
              role="status"
              class="inline w-4 h-4 ml-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            ref="closeButtonQuery"
            type="button"
            data-drawer-hide="drawer-right-query"
            aria-controls="drawer-right-query"
            class="text-gray-400 ml-4 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="handleCloseQuery()"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Close menu</span>
          </button>
        </div>

        <p class="text-xs mb-6 text-gray-500 dark:text-gray-400 text-left">
          Write a business question and the ideal query that would answer it.
          <br />
          <!--        Answers can be validated with tests.-->
        </p>

        <p class="mb-2 text-sm medium text-gray-900 dark:text-white ml-2">Question</p>

        <ResizableTextarea
          :min-rows="2"
          :model-value="drawerItemQuery.question_text"
          :disabled="drawerItemQuery.org_id && drawerItemQuery.org_id !== org.id"
          placeholder="What's our total revenue?"
          @update:model-value="drawerItemQuery.question_text = $event"
        />
        <div class="h-10" />
        <p class="mb-2 text-sm medium text-gray-900 dark:text-white ml-2">Query</p>

        <MonacoEditor
          :key="'question_sql'"
          theme="vs-dark"
          language="sql"
          :options="{
            roundedSelection: true,
            automaticLayout: true,
            minimap: {
              enabled: false,
            },
            formatOnPaste: true,
            formatOnType: true,
            formatOnSave: true,
            tabSize: 2,
            insertSpaces: true,
            autoIndent: true,
            fontSize: '14px',
            lineNumbers: 'off',
            scrollBeyondLastLine: false,
            readOnly: drawerItemQuery.org_id && drawerItemQuery.org_id !== org.id,
          }"
          :height="450"
          :diff-editor="false"
          :value="drawerItemQuery.sql_text"
          @update:value="drawerItemQuery.sql_text = $event"
        />

        <div class="h-10" />
        <div class="h-10" />
      </div>

      <!-- end query side drawer for tables -->
    </div>

    <div
      v-if="backdropIsVisibleQuery"
      id="my_backdrop2"
      class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[90]"
      @click="handleCloseQuery()"
    />

    <div
      v-if="backdropIsVisible"
      id="my_backdrop"
      class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[70]"
      @click="handleCloseTable()"
    />
  </div>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import { initDrawers, initTabs } from 'flowbite'
  import Popup from './Popup.vue'
  import MonacoEditor from 'monaco-editor-vue3'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'
  import CloseIcon from './icons/CloseIcon.vue'
  import * as yaml from 'js-yaml'
  import { useUserStore } from '@/stores/userStore'
  import { useGlobalStore } from '@/stores/globalStore'
  import { formatDistanceToNow, parseISO } from 'date-fns'
  import Search from './Search.vue'
  import { Icon } from '@iconify/vue'
  import LoadingIcon from './icons/LoadingIcon.vue'
  import ResizableTextarea from './ResizableTextarea.vue'
  import ToggleButton from '@/components/ToggleButton.vue'
  import { handleShiftClickSelection } from '@/hooks/handleShiftClickSelection'

  export default {
    name: 'ModelPage',
    components: {
      ToggleButton,
      MonacoEditor,
      CloseIcon,
      Popup,
      Search,
      Icon,
      LoadingIcon,
      ResizableTextarea,
    },
    setup() {
      const orgStore = useOrgStore()
      const { org, groups } = storeToRefs(orgStore)
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const textInput = ref('')
      const autoCompPlaceholder = ref('')
      const userStore = useUserStore()
      const { modelMessage } = storeToRefs(userStore)

      const message = ref('')
      const isSuccess = ref(false)

      onMounted(() => {
        if (modelMessage.value) {
          notify.success(modelMessage.value)
        }

        document.title = 'Model · Dot'
        initDrawers()
        initTabs()
      })

      const showSuggestion = doc => {
        if (textInput.value.length === 0) {
          autoCompPlaceholder.value = ''
          return
        }

        const possibleMatches = groups.value.filter(group => {
          return group.toLowerCase().startsWith(textInput.value.toLowerCase()) && !doc.groups.includes(group)
        })

        if (possibleMatches.length > 0) {
          autoCompPlaceholder.value = possibleMatches[0].replace(textInput.value, '')
        } else {
          autoCompPlaceholder.value = ''
        }
      }

      // expand or collapse sidenav
      const isMobileDevice = ref(window.matchMedia('(max-width: 520px)').matches)

      // Watch for changes in the window width
      const mediaQuery = window.matchMedia('(max-width: 520px)')
      const handleMediaQueryChange = mediaQuery => {
        isMobileDevice.value = mediaQuery.matches
      }

      // Set the initial state based on the window width
      handleMediaQueryChange(mediaQuery)

      // Watch for changes in the window width
      mediaQuery.addEventListener('change', handleMediaQueryChange)

      return {
        org,
        getOrg: orgStore.getOrg,
        showSuggestion,
        textInput,
        autoCompPlaceholder,
        isMobileDevice,
        userStore,
        message,
        isSuccess,
        notify,
        formatDistanceToNow,
        parseISO,
      }
    },
    data() {
      return {
        backdropIsVisible: false,
        backdropIsVisibleQuery: false,
        loading_suggestion: false,
        loading_suggestion_dotml: false,
        save_enabled: false,
        loading_save: false,
        search: '',
        search_drawer: '',
        search_queries: '',
        search_notes: '',
        page: 1,
        page_drawer: 1,
        page_queries: 1,
        perPage: 10,
        perPage_drawer: 10,
        perPage_queries: 10,
        window,
        original_dot_ml: '',
        forceRerender: 0,
        show_orginal_dot_ml: false,
        // show_all_dot_ml: false,
        drawerItem: { columns: [], dot_ml: '' },
        drawerItemQuery: { question_text: '', sql_text: '', active: false },
        uneditedDrawerItem: { columns: [], dot_ml: '' },
        test_placeholder: `tests:
    - self_graded: true
    - rows: [0, 100]
    - has_similar_columns: true
    - sql_where: "1 < 3 AND true"`,
        items: [{ name: 'Loading ..', description: '', dot_ml: '', active: false }],
        queries: [{ question_text: 'Loading ..', sql_text: '', active: false }],
        yamlText: 'version: 2',
        editorOptions: {},
        yaml_errorMessage: null,
        column_sort_attribute: 'position',
        changes_made: false,
        org_note: '',
        table_loading: false,
        // Create a random UUID for the page_seed
        page_seed: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        dropdownPosition: {
          top: 0,
          left: 0,
        },

        search_table_groups: '',
        showTableGroupsDropdown: false,
        selectedTableGroups: [],
        lastSelectedDrawerIndex: null,
        relationships: [],
        search_relationships: '',
        search_table_dropdown: '',
        search_own_columns: '',
        search_referenced_tables: '',
        search_referenced_columns: '',
        relationships_page: 1,
        relationshipsRowsPerPage: 10,
        original_relationships: [],
        loading_relationship_suggestion: false,
      }
    },
    computed: {
      haveRelationshipsBeenModified() {
        const changedRelationships = this.relationships.filter(relationship => {
          const originalRelationship = this.original_relationships.find(originalRelationship => {
            return originalRelationship.relationship_id === relationship.relationship_id
          })

          if (!originalRelationship) {
            return true
          }

          const flatJSONRelationship = JSON.stringify(relationship)
          const flatJSONOriginalRelationship = JSON.stringify(originalRelationship)

          // Compare all values in the relationship
          return flatJSONRelationship !== flatJSONOriginalRelationship
        })

        const deletedRelationships = this.original_relationships.filter(originalRelationship => {
          return !this.relationships.some(relationship => {
            return relationship.relationship_id === originalRelationship.relationship_id
          })
        })

        return changedRelationships.length > 0 || deletedRelationships.length > 0
      },
      drawerItemBlockedBy() {
        return this.itemBlockedBy(this.drawerItem?.id)
      },
      isDrawerItemBlockedByCurrentUser() {
        return this.isItemBlockedByCurrentUser(this.drawerItem?.id)
      },
      filteredTableItems() {
        // add attribute dot_ml to each item if it doesn't exist
        this.items.forEach(item => {
          if (!item.dot_ml) {
            item.dot_ml = ''
          }
        })
        return this.items
          .filter(item => {
            return (
              ((item.name && item.name.toLowerCase().includes(this.search.toLowerCase())) ||
                (item.description && item.description.toLowerCase().includes(this.search.toLowerCase()))) &&
              item.name !== 'dot.meta.summary'
            )
          })
          .filter(item => {
            if (this.selectedTableGroups.length === 0) {
              return true
            }
            return item.groups.some(group => this.selectedTableGroups.includes(group))
          })
          .slice((this.page - 1) * this.perPage, (this.page - 1) * this.perPage + this.perPage)
      },
      filteredColumnItems_drawer() {
        // Pair each item with its index
        const itemsWithIndex = this.drawerItem.columns.map((item, index) => ({ item, index }))

        // sort by this.column_sort_attribute
        const sorted = itemsWithIndex.sort((a, b) => {
          if (this.column_sort_attribute === 'usage') {
            return a.item.usage === b.item.usage ? a.index - b.index : a.item.usage < b.item.usage ? 1 : -1
          } else if (this.column_sort_attribute === 'name') {
            return a.item.column_name === b.item.column_name
              ? a.index - b.index
              : a.item.column_name < b.item.column_name
                ? -1
                : 1
          } else {
            return a.index - b.index
          }
        })

        // Extract sorted items
        const finalSorted = sorted.map(obj => obj.item)

        // convert this.perPage_drawer to number
        const perPage_drawer = Number(this.perPage_drawer)

        return finalSorted
          .filter(item => {
            return (
              (item.column_name && item.column_name.toLowerCase().includes(this.search_drawer.toLowerCase())) ||
              (item.comment && item.comment.toLowerCase().includes(this.search_drawer.toLowerCase())) ||
              (item.sample && item.sample.toLowerCase().includes(this.search_drawer.toLowerCase()))
            )
          })
          .slice((this.page_drawer - 1) * perPage_drawer, (this.page_drawer - 1) * perPage_drawer + perPage_drawer)
      },
      drawer_token_size() {
        let desc_len = 0
        if (this.drawerItem.description) {
          desc_len = this.drawerItem.description.length
        }
        let columns_txt_len = 0
        if (this.drawerItem.columns) {
          desc_len += this.drawerItem.columns
            .filter(item => item.active)
            .map(item => item.name + ' ' + item.comment + ' ' + item.sample)
            .join(', ').length
        }

        return Math.round((desc_len + columns_txt_len) / 3.5)
      },
      tablesShown() {
        if (this.search) {
          return this.filteredTableItems.length
        } else {
          if (!this.items) {
            return 0
          }
          return this.page * this.perPage > this.items.length
            ? this.items.filter(item => {
                return (
                  ((item.name && item.name.toLowerCase().includes(this.search.toLowerCase())) ||
                    (item.description && item.description.toLowerCase().includes(this.search.toLowerCase()))) &&
                  item.name !== 'dot.meta.summary'
                )
              }).length
            : this.page * this.perPage
        }
      },
      allTables() {
        return this.items.filter(item => item.name !== 'dot.meta.summary').length
      },
      drawerColumnsShown() {
        if (this.search_drawer) {
          return this.filteredColumnItems_drawer.length
        } else {
          if (!this.drawerItem.columns) {
            return 0
          }
          return this.page_drawer * this.perPage_drawer > this.drawerItem.columns.length
            ? this.drawerItem.columns.filter(item => {
                return (
                  (item.column_name && item.column_name.toLowerCase().includes(this.search_drawer.toLowerCase())) ||
                  (item.comment && item.comment.toLowerCase().includes(this.search_drawer.toLowerCase())) ||
                  (item.sample && item.sample.toLowerCase().includes(this.search_drawer.toLowerCase()))
                )
              }).length
            : this.page_drawer * this.perPage_drawer
        }
      },
      allDrawerColumns() {
        return this.drawerItem.columns.length
      },
      queriesShown() {
        if (this.search_queries) {
          // code doesn't work?
          return this.filteredQueries?.length ? this.filteredQueries?.length : 0
        } else {
          if (!this.drawerItem.queries) {
            return 0
          }
          return this.page_queries * this.perPage_queries > this.drawerItem?.queries?.length
            ? this.drawerItem.queries.filter(item => {
                return (
                  (item.question_text &&
                    item.question_text.toLowerCase().includes(this.search_queries.toLowerCase())) ||
                  (item.sql_text && item.sql_text.toLowerCase().includes(this.search_queries.toLowerCase()))
                )
              }).length
            : this.page_queries * this.perPage_queries
        }
      },
      allQueries() {
        return this.drawerItem?.queries?.length ? this.drawerItem?.queries?.length : 0
      },
      filteredQueries() {
        const queries = this.drawerItem.queries
          ?.filter(item => {
            return (
              item.question_text.toLowerCase().includes(this.search_queries.toLowerCase()) ||
              item.sql_text.toLowerCase().includes(this.search_queries.toLowerCase())
            )
          })
          .slice((this.page_queries - 1) * this.perPage_queries, this.page_queries * this.perPage_queries)

        return queries
      },
      filteredNotes() {
        return this.drawerItem.note_dicts?.filter(item => {
          return item.note.toLowerCase().includes(this.search_notes.toLowerCase())
        })
      },
      allTableGroups() {
        if (!this.items || this.items?.length === 0) {
          return []
        }

        let groups = this.items
          .map(item => item.groups)
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index)
          .filter(group => Boolean(group)) // filter undefined

        if (groups.length > 0) {
          groups = groups.map(group => {
            return {
              value: group,
              count: this.items.filter(item => item.groups.includes(group)).length,
              label: group
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
            }
          })
        }

        if (this.search_table_groups) {
          groups = groups.filter(group => group.label.toLowerCase().includes(this.search_table_groups.toLowerCase()))
        }

        return groups
      },
      filteredRelationships() {
        if (!this.relationships) {
          return []
        }

        const relationships = this.relationships
          .filter(relationship =>
            JSON.stringify(Object.values(relationship)).toLowerCase().includes(this.search_relationships.toLowerCase())
          )
          .slice(
            (this.relationships_page - 1) * this.relationshipsRowsPerPage,
            this.relationships_page * this.relationshipsRowsPerPage
          )
          .sort((a, b) => {
            // sort by active first
            return a.active === b.active ? 0 : a.active ? -1 : 1
          })

        return relationships
      },
      relationshipsShown() {
        if (this.search_relationships) {
          return this.filteredRelationships.length
        } else {
          return this.relationships_page * this.relationshipsRowsPerPage > this.relationships.length
            ? this.relationships.length
            : this.relationships_page * this.relationshipsRowsPerPage
        }
      },
      allRelationships() {
        return this.relationships.length
      },
      orgNoteChanged() {
        return this.org_note !== this.org.note
      },
    },
    watch: {
      drawerItem: {
        deep: true,
        async handler(newVal, oldVal) {
          // Go through all relationships of this table
          // If they don't have the property 'active', add it and set it to true
          if (newVal.relationships) {
            newVal.relationships.forEach(relationship => {
              if (!Object.prototype.hasOwnProperty.call(relationship, 'active')) {
                relationship.active = true
              }
            })
          }

          await this.getOrg()

          // Check if the ID is new
          if (newVal.id !== oldVal.id) {
            console.log(`New ID: ${newVal.id}`)
            // Copy the new value to the uneditedDrawerItem
            this.uneditedDrawerItem = JSON.parse(JSON.stringify(newVal))

            if (newVal?.id) {
              if (!this.isDrawerItemBlockedByCurrentUser) {
                console.log('not blocked by current user')
              } else {
                this.blockTable(newVal?.id)
              }
            } else {
              this.unblockTable(oldVal?.id)
            }
          } else {
            this.save_enabled = true
            this.changes_made = true
          }
        },
      },
      haveRelationshipsBeenModified: {
        handler(newVal, oldVal) {
          // prevent double save
          if (newVal && !oldVal) {
            // save relationships
            this.saveRelationships()
          }
        },
      },
    },
    created() {
      // call api  /tables to get tables, also send cookie bearer token
      this.getTables()
      this.getRelationships()
      this.org_note = this.org.note

      window.addEventListener('beforeunload', this.unblockCurrentDrawerItem)
      // // call api  /tables to get queries, also send cookie bearer token
      // axios
      //   .get('/api/queries', { withCredentials: true })
      //   .then(response => {
      //     if (response.data) {
      //       console.log(response.data)
      //       this.queries = response.data
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
    methods: {
      itemBlockedBy(itemID) {
        if (!this.org?.blocked_elements) {
          return null
        }

        const blockedElement = this.org.blocked_elements.find(blockedElement => {
          return blockedElement.id === itemID
        })

        if (!blockedElement) {
          return null
        }

        return blockedElement
      },
      isItemBlockedByCurrentUser(itemID) {
        // Check org for blocked_elements
        // This contains an array of blocked elements
        // Each element is an object with the properties id, user_id, user_name and timestamp
        // If the current drawerItem is in the array and the user_id is not the current user, return false
        // In all other cases, even if blocked_elements does not exist or is an empty array, return true
        if (!this.org?.blocked_elements) {
          return true
        }

        const blockedElement = this.org.blocked_elements.find(blockedElement => {
          return blockedElement.id === itemID
        })

        if (!blockedElement) {
          return true
        }

        if (blockedElement.user_id === this.userStore.user.id && blockedElement.seed === this.page_seed) {
          return true
        }

        return false
      },
      blockTable(id) {
        axios
          .post('/api/block_element', { id: id, seed: this.page_seed }, { withCredentials: true })
          .then(() => {
            this.getOrg()
          })
          .catch(error => {
            console.log(error)
          })
      },
      unblockTable(id) {
        axios.post('/api/unblock_element', { id: id, seed: this.page_seed }, { withCredentials: true }).then(() => {
          this.getOrg()
        })
      },
      unblockCurrentDrawerItem() {
        if (this.drawerItem?.id) {
          this.unblockTable(this.drawerItem.id)
        }
      },
      takeOverTable(id) {
        axios
          .post('/api/take_over_element', { id: id, seed: this.page_seed }, { withCredentials: true })
          .then(() => {
            this.getOrg()
          })
          .catch(error => {
            console.log(error)
          })
      },
      getTables() {
        axios
          .get('/api/tables', { withCredentials: true })
          .then(response => {
            if (response.data) {
              console.log(response.data)
              this.items = response.data
              // if item has attribute 'archive' set, set 'active' to true
              this.items.forEach(item => {
                if (item.archived) {
                  item.active = true
                }
              })

              // order items by active first
              this.items.sort((a, b) => {
                return a.active === b.active ? 0 : a.active ? -1 : 1
              })

              // order all columns (if exists) in each table by active first
              this.items.forEach(item => {
                if (item.columns) {
                  item.columns.sort((a, b) => {
                    return a.active === b.active ? 0 : a.active ? -1 : 1
                  })
                }
              })

              const potentialUpdatedDrawerItem = this.items.filter(item => item.id === this.drawerItem.id)

              if (potentialUpdatedDrawerItem.length > 0) {
                this.drawerItem = potentialUpdatedDrawerItem[0]
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      getRelationships() {
        axios
          .get('/api/relationships', { withCredentials: true })
          .then(response => {
            if (response.data && response.data.length > 0) {
              // original_relationships needs to be a deep copy of the relationships
              this.original_relationships = JSON.parse(JSON.stringify(response.data))

              this.relationships = response.data
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      getRelationshipSuggestions() {
        // the endpoint can be found here:
        // @app.get("/api/relationship_suggestions", dependencies=[Depends(get_current_admin_user)])
        // def get_relationship_suggestions_route(user: dict = Depends(get_current_admin_user)):

        this.loading_relationship_suggestion = true

        axios
          .get('/api/relationship_suggestions', { withCredentials: true })
          .then(response => {
            if (response.data) {
              // Notify about the amount of suggestions
              this.notify.info(`${response.data.length} relationship suggestions found`)
              this.getRelationships()
            }

            this.loading_relationship_suggestion = false
          })
          .catch(error => {
            console.log(error)
            this.loading_relationship_suggestion = false
          })
      },
      async saveRelationships() {
        // relationships are defined like this:
        // class Relationship(BaseModel):
        //   relationship_id: Optional[int] = None
        //   doc_id: str
        //   table: str
        //   own_columns: List[str]
        //   columns: List[str]
        //   type: str
        //   active: bool = False
        //   incoming: bool = False

        // Changes can be anything in a relationship that already has a relationship_id
        // As long as it differs from the original_relationships, it should be saved
        const changedRelationships = this.relationships.filter(relationship => {
          const originalRelationship = this.original_relationships.find(originalRelationship => {
            return originalRelationship.relationship_id === relationship.relationship_id
          })

          if (!originalRelationship) {
            return true
          }

          const flatJSONRelationship = JSON.stringify(relationship)
          const flatJSONOriginalRelationship = JSON.stringify(originalRelationship)

          // Compare all values in the relationship
          return flatJSONRelationship !== flatJSONOriginalRelationship
        })

        const deletedRelationships = this.original_relationships.filter(originalRelationship => {
          return !this.relationships.some(relationship => {
            return relationship.relationship_id === originalRelationship.relationship_id
          })
        })

        // Save the changed relationships
        await axios.post('/api/relationships', changedRelationships, { withCredentials: true })

        // Delete the deleted relationships
        await axios.delete(
          '/api/relationships',
          { data: deletedRelationships.map(relationship => relationship.relationship_id) },
          { withCredentials: true }
        )

        // fetch the relationships again
        this.getRelationships()

        this.notify.success('Relationships saved successfully')
      },
      getTable(id) {
        axios
          .get('/api/tables/' + id, { withCredentials: true })
          .then(response => {
            if (response.data) {
              // Replace the table in items
              // Doing all the transformations that are also done in getTables
              const table = response.data
              if (table.archived) {
                table.active = true
              }

              if (table.columns) {
                table.columns.sort((a, b) => {
                  return a.active === b.active ? 0 : a.active ? -1 : 1
                })
              }

              const index = this.items.findIndex(item => item.id === id)
              this.items[index] = table

              if (this.drawerItem.id === id) {
                this.drawerItem = table
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      isValidYAML(str) {
        this.yaml_errorMessage = null
        try {
          yaml.load(str)
          return true
        } catch (e) {
          this.yaml_errorMessage = e.message
          return false
        }
      },
      autoFormatYAML(str) {
        try {
          const obj = yaml.safeLoad(str)
          return yaml.safeDump(obj)
        } catch {
          return str // Return original if invalid
        }
      },
      millify(num) {
        if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1) + 'B'
        }
        if (num >= 1000000) {
          return (num / 1000000).toFixed(1) + 'M'
        }
        if (num >= 1000) {
          return (num / 1000).toFixed(1) + 'K'
        }
        return num.toString()
      },
      sort_columns_by_attr(attribute) {
        // toggle this.column_sort_attribute value between usage, name and position
        this.column_sort_attribute = attribute
      },
      deleteQuery(query) {
        // remove query also from table
        this.drawerItem.queries = this.drawerItem.queries.filter(item => item.id !== query.id)
        const table_id = this.drawerItem.id
        axios
          .delete('/api/queries/' + query.id + '/' + table_id, { withCredentials: true })
          .then(response => {
            if (response.data) {
              console.log(response.data)
              this.notify.success('Query deleted successfully')
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      deleteDataSource(table_id) {
        // use endpoint @app.post("/api/delete_table", dependencies=[Depends(get_current_admin_user)])
        // async def delete_table(table_id: str = Body(embed=True), user=Depends(get_current_admin_user)):
        axios
          .post('/api/delete_table', { table_id }, { withCredentials: true })
          .then(response => {
            if (response.data) {
              console.log(response.data)
              this.notify.info('data source deleted successfully')
              this.items = this.items.filter(item => item.id !== table_id)
              this.handleCloseTable()
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      isJson(str) {
        try {
          JSON.parse(str)
        } catch {
          return false
        }
        return true
      },
      sortBy(field) {
        this.items.sort((a, b) => {
          let valueA = a[field].toLowerCase()
          let valueB = b[field].toLowerCase()
          if (valueA < valueB) {
            return -1
          }
          if (valueA > valueB) {
            return 1
          }
          return 0
        })
      },
      toggleAll_drawer() {
        let is_active = this.filteredColumnItems_drawer.filter(item => item.active).length > 0
        this.filteredColumnItems_drawer.forEach(item => {
          item.active = !is_active
        })
      },

      async suggestDescriptions(item) {
        const table = this.items.find(table => table.id === (item || this.drawerItem)?.id)

        if (!table) {
          console.error('Table not found')
          return
        }

        this.loading_suggestion = true

        // Step 1: Start the suggestion process
        try {
          const suggestResponse = await axios.post('/api/suggest', { table }, { withCredentials: true })
          const requestId = suggestResponse.data

          // Check if request ID is received
          if (!requestId) {
            console.error('Request ID not received')
            this.loading_suggestion = false
            return
          }

          // Step 2: Poll for suggestions
          const fetchSuggestions = () => {
            const fetchAndProcess = async (resolve, reject) => {
              try {
                const fetchResponse = await axios.get(`/api/fetch_suggestions?request_id=${requestId}`, {
                  withCredentials: true,
                })

                if (fetchResponse.data && fetchResponse.data.request_id === requestId) {
                  // Update the UI with the received data
                  table.columns = fetchResponse.data.columns
                  table.description = fetchResponse.data.description
                  table.num_rows = fetchResponse.data.num_rows
                  table.warning = fetchResponse.data.warning
                  table.queries = fetchResponse.data.queries
                  table.relationships = fetchResponse.data.relationships
                  table.request_id = requestId

                  // if (fetchResponse.data.warning) {
                  //   this.notify.error(fetchResponse.data.warning)
                  // }

                  // Check if the request is completed
                  if (fetchResponse.data.request_complete) {
                    this.loading_suggestion = false
                    resolve()
                  } else {
                    // If the request is not completed, poll again after a delay
                    setTimeout(() => fetchAndProcess(resolve, reject), 2000) // Delay of 2 seconds
                  }
                } else {
                  console.error('Mismatch in request IDs')
                  this.loading_suggestion = false
                  reject()
                }
              } catch (error) {
                console.error(error)
                this.loading_suggestion = false
                reject()
              }
            }

            // Return a new Promise
            return new Promise(fetchAndProcess)
          }

          // Initial call to fetch suggestions
          return fetchSuggestions()
        } catch (error) {
          console.error(error)
          this.loading_suggestion = false
        }
      },

      async suggestNote() {
        // call api/suggest_note to get suggested note and store it in org_note
        this.loading_suggestion = true

        axios
          .post('/api/suggest_note', { withCredentials: true })
          .then(response => {
            if (response.data) {
              console.log(response.data)
              // update object drawerItem with new values
              this.org_note = response.data
            }
            this.loading_suggestion = false
          })
          .catch(error => {
            console.log(error)
            this.loading_suggestion = false
          })
      },

      async suggestDotML() {
        // call api to suggest dotml yaml
        const table = this.drawerItem
        this.loading_suggestion_dotml = true

        axios
          .post('/api/suggest_dot_yaml', { table }, { withCredentials: true })
          .then(response => {
            if (response.data) {
              console.log(response.data)
              // update object drawerItem with new values
              this.drawerItem.dot_ml = response.data

              if (response.data.warning) {
                this.notify.error(response.data.warning)
                this.forceRerender += 1
              }
            }
            this.loading_suggestion_dotml = false
          })
          .catch(error => {
            console.log(error)
            this.loading_suggestion_dotml = false
          })
      },
      async toggleTableStatus(table) {
        if (table.org_id !== this.org.id) {
          this.notify.error('Demo data items are not editable')
          this.items = this.items.map(item => {
            if (item.id === table.id) {
              item.active = true
            }
            return item
          })
        } else {
          this.table_loading = table.id

          table = { ...table, active: !table.active }
          this.items = this.items.map(item => {
            if (item.id === table.id) {
              item.active = table.active
            }
            return item
          })

          if (!this.isItemBlockedByCurrentUser(table.id)) {
            this.notify.error('This table is currently being edited by another user')
            this.loading_save = false
            this.table_loading = null
            return
          }

          this.loading_save = true

          if (table.active) {
            // Call /api/activate_table to activate the table
            // This will also trigger the initial suggest process
            axios
              .post('/api/activate_table', { table_id: table.id }, { withCredentials: true })
              .then(async response => {
                console.log(response.data)

                // Reload the table
                await this.getTable(table.id)

                this.notify.success('Table enabled')

                this.loading_save = false
                this.table_loading = null
              })
              .catch(error => {
                this.notify.error('Error saving table: ' + error)
                this.loading_save = false
              })
          } else {
            // Call /api/deactivate_table to deactivate the table
            axios
              .post('/api/deactivate_table', { table_id: table.id }, { withCredentials: true })
              .then(async response => {
                console.log(response.data)
                this.notify.success('Table disabled')

                // Reload the table
                await this.getTable(table.id)

                this.loading_save = false
                this.table_loading = null
              })
              .catch(error => {
                this.notify.error('Error saving table: ' + error)
                this.loading_save = false
              })
          }
        }
      },
      selectTable(item) {
        if (item.id === this.table_loading) {
          return
        }

        this.$refs.sideDrawerButton.click()

        item.dot_ml = item.dot_ml || '' // set dotml
        this.show_orginal_dot_ml = false //reset diff show dotml
        this.drawerItem.id === item.id && this.original_dot_ml
          ? (this.show_orginal_dot_ml = true)
          : (this.original_dot_ml = item.dot_ml) // keep original dotml and show it when changed and item is the same

        this.drawerItem = item // set drawer item

        this.forceRerender += 1
        this.save_enabled = false
        this.backdropIsVisible = true
        this.page_drawer = 1
        initTabs()
      },
      async toggleQueryStatus(event, query) {
        if (query.org_id !== this.org.id) {
          this.notify.error('Demo data items are not editable')
          return
        }
        query.active = !query.active
        // save item status (active or not)
        axios
          .post('/api/save_query_doc', { query: query, table_id: query.table_id }, { withCredentials: true })
          .then(response => {
            console.log(response.data)
            this.notify.success('Saved successfully')
            this.loading_save = false
          })
          .catch(error => {
            console.log(error)
            this.loading_save = false
          })
      },
      async saveItem(item) {
        console.log('save item')

        // call api to save item
        const table = item || this.drawerItem

        if (table.org_id !== this.org.id) {
          this.notify.error('Demo data items are not editable')
          return
        }

        this.loading_save = true

        // if dot_ml is set, check if it is valid yaml
        if (table.dot_ml && !this.isValidYAML(table.dot_ml)) {
          this.message = 'This is not valid YAML. Please fix it and try again.\n' + this.yaml_errorMessage
          this.notify.error(this.message)
          this.loading_save = false
          return
        }

        if (table.dot_ml) {
          table.dot_ml = this.autoFormatYAML(table.dot_ml)
          // convert all names to lowercase and replace non-alphanumeric characters with underscore
          table.dot_ml = table.dot_ml.replace(/name: (.*)/g, (match, p1) => {
            return 'name: ' + p1.toLowerCase().replace(/[^a-z0-9]/g, '_')
          })
        }

        axios
          .post('/api/save_table_doc', { table }, { withCredentials: true })
          .then(() => {
            let all_relationship_model_ids = []

            if (this.uneditedDrawerItem.relationships?.length) {
              all_relationship_model_ids = [
                ...this.uneditedDrawerItem.relationships.map(relationship => relationship.table),
              ]
            }
            if (table.relationships?.length) {
              all_relationship_model_ids = [
                ...all_relationship_model_ids,
                ...table.relationships.map(relationship => relationship.table),
              ]
            }
            const unique_relationship_model_ids = [...new Set(all_relationship_model_ids)]
            // Reload all the tables that have relationships with this table
            unique_relationship_model_ids.forEach(id => {
              this.getTable(id)
            })

            this.notify.success('Saved successfully')
            this.loading_save = false
            this.save_enabled = false
            this.changes_made = false
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.detail) {
              this.notify.error('Error saving table: ' + error.response.data.detail)
            } else {
              this.notify.error()
            }
            this.loading_save = false
          })
      },

      async saveNote() {
        // call api to save note
        const note = this.org_note
        this.loading_save = true

        await this.getOrg()

        if (!this.isItemBlockedByCurrentUser('company_note')) {
          this.notify.error('This note is currently being edited by another user')
          this.loading_save = false
          return
        }

        axios
          .post('/api/save_note', { note }, { withCredentials: true })
          .then(response => {
            console.log(response.data)
            this.notify.success('Saved successfully')
            this.loading_save = false
          })
          .catch(error => {
            console.log(error)
            this.loading_save = false
          })
      },

      discardItem() {
        this.$refs.saveChangesPopup.hidePopup()
        this.backdropIsVisible = false
        this.$refs.closeButtonTable?.click()
        this.drawerItem = { columns: [], dot_ml: '' }
        this.save_enabled = false
        this.changes_made = false
      },
      async saveQuery() {
        // call api to save query
        const query = this.drawerItemQuery
        this.loading_save = true
        console.log(query, 'queries to save')

        axios
          .post('/api/save_query_doc', { query: query, table_id: query.table_id }, { withCredentials: true })
          .then(response => {
            // if (response.data) {
            console.log(response.data)
            console.log('Saved Query')
            this.notify.success('Saved Question')
            // }
            this.loading_save = false
            this.getTables()
          })
          .catch(error => {
            console.log(error)
            this.loading_save = false
          })
      },
      async validateQuery() {
        // call api to validate query
        const query = this.drawerItemQuery
        this.loading_suggestion = true
        this.drawerItemQuery.test_logs = 'Loading ...'
        axios
          .post('/api/validate_query', { query }, { withCredentials: true })
          .then(response => {
            if (response.data) {
              console.log(response.data)
              this.message = response.data.message + '<br>See logs below ⬇️'
              this.isSuccess = response.data.success
              if (this.isSuccess) {
                this.notify.success(this.message)
              } else {
                this.notify.error(this.message)
              }
              this.drawerItemQuery.test_logs = response.data.log_messages.join('\n')
            }
            this.loading_suggestion = false
          })
          .catch(error => {
            console.log(error)
            this.loading_suggestion = false
          })
      },
      async addEmptyRelationship() {
        this.relationships.push({
          table: '',
          columns: [],
          own_columns: [],
          type: '',
          doc_id: '',
        })

        // init drawers with the next tick
        this.$nextTick(() => {
          initDrawers()
        })
      },
      addTableToGroup(doc, event, group) {
        // Only execute if keyup event is enter, space or comma
        if (event.keyCode !== 13 && event.keyCode !== 32 && event.keyCode !== 188) {
          return
        }

        if (!group) {
          group = this.textInput
        }

        group = (group || this.textInput).trim().toLowerCase()

        doc.groups.push(group)
        // Clear input
        this.textInput = ''

        // axios.post('/api/add_doc_to_group', { doc_id: doc.id, group: event.target.value }, { withCredentials: true })
        //   .then(() => {
        //     doc.groups.push(event.target.value);
        //     // Clear input
        //     event.target.value = "";
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      },
      removeTableFromGroup(doc, group) {
        doc.groups = doc.groups.filter(g => g !== group)
        // axios.post('/api/remove_doc_from_group', { doc_id: doc.id, group }, { withCredentials: true })
        //   .then(() => {
        //     doc.groups = doc.groups.filter((g) => g !== group);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      },
      addAutocomplete(doc, event, group) {
        if (!group) {
          group = this.textInput
        }

        group += this.autoCompPlaceholder
        group = group.trim().toLowerCase()

        if (!doc.groups.includes(group)) {
          doc.groups.push(group)
        }

        // Clear input
        this.textInput = ''
        this.autoCompPlaceholder = ''
      },
      handleCloseTable() {
        if (this.changes_made && this.save_enabled) {
          this.$refs.saveChangesPopup.showPopup()
        } else {
          this.backdropIsVisible = false
          this.$refs.closeButtonTable.click()
          this.drawerItem = { columns: [], dot_ml: '' }
        }
      },
      handleCloseQuery() {
        this.backdropIsVisibleQuery = false
        this.$refs.closeButtonQuery.click()
      },
      hasError(str) {
        if (!str) {
          return false
        }

        // return false if there's a ; in the first 10 characters of the str
        if (str.slice(0, 20).includes(';')) {
          return false
        }

        // return true if there's an 'error' in the string
        if (str.toLowerCase().includes('error')) {
          return true
        }

        return false
      },
      setDropdownPosition(event, dropdown, index) {
        if (dropdown === 'own_columns_keys') {
          this.$refs[`${index}-own_column_keys`][0]?.classList?.toggle('hidden')
        } else {
          for (let i = 0; i < this.relationships?.length; i++) {
            if (this.$refs[`${i}-own_column_keys`]) {
              this.$refs[`${i}-own_column_keys`][0]?.classList?.add('hidden')
            }
          }
        }

        if (dropdown === 'column_keys') {
          this.$refs[`${index}-column_keys`][0]?.classList?.toggle('hidden')
        } else {
          for (let i = 0; i < this.relationships?.length; i++) {
            if (this.$refs[`${i}-column_keys`]) {
              this.$refs[`${i}-column_keys`][0]?.classList?.add('hidden')
            }
          }
        }

        if (dropdown === 'table_keys') {
          this.$refs[`${index}-table_keys`][0]?.classList?.toggle('hidden')
        } else {
          for (let i = 0; i < this.relationships?.length; i++) {
            if (this.$refs[`${i}-table_keys`]) {
              this.$refs[`${i}-table_keys`][0]?.classList?.add('hidden')
            }
          }
        }

        if (dropdown === 'join_type') {
          this.$refs[`${index}-join_type`][0]?.classList?.toggle('hidden')
        } else {
          for (let i = 0; i < this.relationships?.length; i++) {
            if (this.$refs[`${i}-join_type`]) {
              this.$refs[`${i}-join_type`][0]?.classList?.add('hidden')
            }
          }
        }

        if (dropdown === 'referenced_tables') {
          this.$refs[`${index}-referenced_tables`][0]?.classList?.toggle('hidden')
        } else {
          for (let i = 0; i < this.relationships?.length; i++) {
            if (this.$refs[`${i}-referenced_tables`]) {
              this.$refs[`${i}-referenced_tables`][0]?.classList?.add('hidden')
            }
          }
        }

        // Get the position of the clicked button
        const buttonRect = event?.target?.getBoundingClientRect()

        if (buttonRect) {
          this.dropdownPosition = {
            left: dropdown === 'column_keys' ? buttonRect.x - 150 : buttonRect.x,
            top: buttonRect.y + buttonRect.height,
          }
        }
      },

      getRelationsipTable() {
        // filter by active first and it includes the search
        let rTables = this.items.filter(
          x =>
            x.active &&
            x.org_id === this.org.id &&
            x.id.toLowerCase().includes(this.search_table_dropdown.toLowerCase())
        )

        rTables.sort((a, b) => {
          return a.active === b.active ? 0 : a.active ? -1 : 1
        })

        // sort by name
        rTables.sort((a, b) => {
          return a.name.toLowerCase() === b.name.toLowerCase()
            ? 0
            : a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : 1
        })

        // filter out table with id 'dot.meta.summary'
        rTables = rTables.filter(item => item.id !== 'dot.meta.summary')

        return rTables
      },

      getRelationshipTableColumns(relationship) {
        const originalColumns = this.items.find(x => x.id === relationship.doc_id)?.columns || []

        // Clone the array to avoid mutating original reactive data
        const rColumns = [...originalColumns]

        // Perform sorting and filtering on the cloned array
        const sortedFilteredColumns = rColumns
          .sort(
            (a, b) =>
              relationship.own_columns.includes(b.column_name) - relationship.own_columns.includes(a.column_name) ||
              a.column_name.localeCompare(b.column_name)
          )
          .filter(column => column.column_name.toLowerCase().includes(this.search_own_columns.toLowerCase()))

        return sortedFilteredColumns
      },

      getReferencedRelationshipTables(relationship) {
        // filter by active first and it includes the search
        let rTables = this.items.filter(
          x => x.active && x.id.toLowerCase().includes(this.search_referenced_tables.toLowerCase())
        )

        const doc_id = relationship.doc_id

        if (doc_id) {
          // get the table with the doc_id
          const doc = this.items.find(x => x.id === doc_id)

          if (doc) {
            // get the connection_id of the doc
            const connection_id = doc.connection_id

            // get the tables with the same connection_id
            rTables = rTables.filter(x => x.connection_id === connection_id)
          }
        }

        // sort by name
        rTables.sort((a, b) => {
          return a.name.toLowerCase() === b.name.toLowerCase()
            ? 0
            : a.name.toLowerCase() < b.name.toLowerCase()
              ? -1
              : 1
        })

        return rTables
      },

      getReferencedRelationshipTableColumns(relationship) {
        const originalColumns = this.items.find(x => x.id === relationship.table)?.columns || []

        // Clone the array to avoid mutating original reactive data
        const rColumns = [...originalColumns]

        // Perform sorting and filtering on the cloned array
        let sortedFilteredColumns = rColumns
          .sort(
            (a, b) =>
              relationship.columns.includes(b.column_name) - relationship.columns.includes(a.column_name) ||
              a.column_name.localeCompare(b.column_name)
          )
          .filter(column => column.column_name.toLowerCase().includes(this.search_referenced_columns.toLowerCase()))

        const ownColumns = relationship.own_columns
        let ownColumnDataTypes = []
        if (ownColumns.length > 0) {
          // get the data types of the own columns
          ownColumnDataTypes = sortedFilteredColumns
            .filter(column => ownColumns.includes(column.column_name))
            .map(column => column.data_type)
        }

        // sort sortedFilteredColumns. Columns that are in the own_columns should be first, then columns with the same data type as the own_columns
        sortedFilteredColumns = sortedFilteredColumns.sort((a, b) => {
          return (
            ownColumns.includes(b.column_name) - ownColumns.includes(a.column_name) ||
            ownColumnDataTypes.includes(b.data_type) - ownColumnDataTypes.includes(a.data_type) ||
            a.column_name.localeCompare(b.column_name)
          )
        })

        return sortedFilteredColumns
      },

      toggleTableGroup(value) {
        if (this.selectedTableGroups.includes(value)) {
          this.selectedTableGroups = this.selectedTableGroups.filter(group => group !== value)
        } else {
          this.selectedTableGroups.push(value)
        }
      },

      handleDrawerCheckboxToggle(index, item, event) {
        handleShiftClickSelection(
          index,
          item,
          this.filteredColumnItems_drawer,
          'active', // The property to toggle
          this.lastSelectedDrawerIndex,
          newLastSelectedIndex => {
            this.lastSelectedDrawerIndex = newLastSelectedIndex
          },
          event
        )
      },
    },
  }
</script>

<style>
  .dot-inline-shadow {
    -webkit-box-shadow: inset 0px -5px 8px -2px rgba(129, 129, 129, 0.22);
    box-shadow: inset 0px -5px 8px -4px rgba(129, 129, 129, 0.22);
  }
</style>

<style>
  .monaco-editor-vue3 {
    height: 140px;
    background: #1e1e1e;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dark .monaco-editor-vue3 {
    background: #1e1e1e;
  }

  .virtual-text {
    width: auto;
    height: 100%;
    position: absolute;
    top: 7px;
    left: 6px;
    overflow: hidden;
  }

  .hidden-text {
    width: auto;
    visibility: hidden;
    /* border: 1px solid green; */
    float: left;
    font-size: 15px;
  }

  .autocomplete {
    color: silver;
    z-index: 5;
    background-color: transparent !important;
    width: auto;
    border-bottom: none;
    float: left;
    outline: none;
    border: none;
    /* border: 1px solid red; */
    line-height: 1.5 !important;
    padding: 0px !important;
    margin: 0px !important;
    pointer-events: none;
  }

  .grow {
    position: relative;
  }

  .stroke-green-500 {
    stroke: #22c55e;
  }

  .dark .stroke-green-300 {
    stroke: #86efac;
  }
</style>

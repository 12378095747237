<template>
  <section class="bg-white dark:bg-gray-900 w-full">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>

      <div class="w-full bg-white rounded-2xl border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-900 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            id="verify_email_header"
            class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
          >
            Verify your email
          </h1>
          <p v-if="username" class="text-gray-900 text-md dark:text-white">
            Please supply the token that was sent to {{ username }}.
          </p>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="token" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Token</label>
              <input
                id="token"
                type="text"
                name="token"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-xl focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="123456"
                required=""
              />
            </div>
            <button
              id="verify-button"
              class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center gap-2 justify-center disabled:cursor-not-allowed"
              :disabled="verifying || resending"
              @click.prevent="verify"
            >
              <LoadingIcon v-if="verifying" class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin" />
              Verify
            </button>
            <div>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Didn't receive an email?
                <button
                  class="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer disabled:cursor-not-allowed"
                  :disabled="resending"
                  @click.prevent="resend_verify_email"
                >
                  {{ resending ? 'Resending...' : 'Click to resend' }}
                </button>
              </p>
              <p v-if="$route.query.username" class="text-sm font-light text-gray-500 dark:text-gray-400 mt-2">
                Not {{ username }}?
                <span
                  class="font-medium text-blue-600 hover:underline dark:text-blue-500 cursor-pointer"
                  @click.prevent="change_email"
                >
                  Click to change
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { onMounted } from 'vue'
  import axios from 'axios'
  import { useGlobalStore } from './stores/globalStore'
  import LoadingIcon from './components/icons/LoadingIcon.vue'

  // store login state in local storage
  // https://stackoverflow.com/questions/2010892/storing-objects-in-html5-localstorage
  export default {
    name: 'RegisterPage',
    components: {
      LoadingIcon,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify
      onMounted(() => {})

      return {
        notify,
      }
    },
    data() {
      return {
        verifying: false,
        resending: false,
      }
    },
    computed: {
      // get username from local storage
      username() {
        return localStorage.getItem('username') || this.$route.query.username
      },
    },
    methods: {
      async verify() {
        // get inputs fields
        let token = document.getElementById('token').value

        if (token === '') {
          this.notify.error('Please fill in all fields.')
          return
        }

        // remove whitespaces from token
        token = token.replace(/\s/g, '')

        // get username from local storage
        let username = localStorage.getItem('username')

        if (username === null) {
          // get username as query parameter
          username = this.$route.query.username

          if (username === null) {
            this.notify.error('No username found, please contact hi@getdot.ai')
            return
          }
        }

        // call /auth/token login api with email and password using axios
        try {
          this.verifying = true
          const response = await axios.post('/api/auth/verify', { username, token })
          const { data } = response
          console.log(data)
          if (response.status === 200) {
            localStorage.setItem('login', JSON.stringify({ email: username }))
            window.location.href = data.is_first_user ? '/survey' : '/'
          }
        } catch (error) {
          this.notify.error(error.response.data.detail)
          console.log('wrong credentials')
        } finally {
          this.verifying = false
        }
      },
      async resend_verify_email() {
        if (this.resending) {
          return
        }

        // get username from local storage
        let username = localStorage.getItem('username')

        if (username === null) {
          // get username as query parameter
          username = this.$route.query.username

          if (username === null) {
            this.notify.error('No username found, please contact hi@getdot.ai')
            return
          }
        }
        try {
          this.resending = true

          const response = await axios.post('/api/auth/resend_verification_mail', { username })
          const { data } = response
          console.log(data)
          if (response.status === 200) {
            this.notify.success(
              'Verification email sent to ' + username + '. Please check your inbox (including spam).'
            )
          }
        } catch (error) {
          this.notify.error(error.response.data.detail)
          console.log('something went wrong')
        } finally {
          this.resending = false
        }
      },

      change_email() {
        this.$router.push('/register')
      },
    },
  }
</script>

<style></style>

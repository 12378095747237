import axios from 'axios'
import router from './router'

const axiosInstance = axios.create({
  // Optional: your default config, base URLs, etc.
})

function isRunningInIframe() {
  try {
    return window.self !== window.top
  } catch {
    return true // Some browsers throw if you try to access window.top cross-origin
  }
}

function parseURLParams(url) {
  const params = {}
  const urlParts = url.split('?')
  if (urlParts.length >= 2) {
    const queryString = urlParts[1]
    const keyValuePairs = queryString.split('&')
    for (const pair of keyValuePairs) {
      const pairParts = pair.split('=')
      const key = decodeURIComponent(pairParts[0])
      const value = decodeURIComponent(pairParts[1] || '')
      params[key] = value
    }
  }
  return params
}

if (isRunningInIframe()) {
  const iframeSrc = document.URL
  const access_token = parseURLParams(iframeSrc).access_token

  if (access_token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
  } else {
    delete axiosInstance.defaults.headers.common['Authorization']
  }
} else {
  console.log('Not running in iframe, skipping axios interceptors setup ...')
}

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const ignore_paths = ['/login', '/register', '/invite', '/reset_password', '/verify', '/share']

    let router_path_doesnt_start_with_ignore_path = true
    ignore_paths.forEach(path => {
      if (window.location.href.includes(path)) {
        router_path_doesnt_start_with_ignore_path = false
      }
    })

    if (
      error.response &&
      [401, 403, 406].includes(error.response.status) &&
      router_path_doesnt_start_with_ignore_path
    ) {
      // Redirect to the login page.
      console.log('Unauthenticated, logging out ...')
      router.push('/login')
    }

    // You can also add any logging you want here or toast notifications.
    console.log(error)
    return Promise.reject(error)
  }
)

export default axiosInstance

<template>
  <button
    class="primary-toggle"
    :class="{
      'toggle-parent-active': active,
      'toggle-parent-inactive': !active,
    }"
    @click="$emit('update:active', !active)"
  >
    <span
      class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
      :class="{
        'left-[2px]': !active,
        'right-[2px]': active,
      }"
    />
  </button>
</template>

<script>
  export default {
    name: 'ToggleButton',

    components: {},
    props: {
      classList: {
        type: String,
        default: '',
      },
      active: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['update:active'],
  }
</script>

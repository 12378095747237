<template>
  <div
    id="dislikeModal"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-xl shadow dark:bg-gray-800">
        <!-- Modal header -->
        <div class="flex items-start justify-between p-4 px-6 border-b rounded-t dark:border-gray-700">
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Provide additional feedback</h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="dislikeModal"
          >
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6">
          <textarea
            v-model="description"
            class="w-full px-4 py-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-xl focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:focus:ring-gray-600"
            rows="4"
            placeholder="What was the issue with this response? How can it be improved?"
          />

          <!-- Multiple choice checkboxes -->
          <div class="flex items-center mt-2 pl-0.5">
            <input
              id="checkbox-not_helpful"
              v-model="checkboxes"
              type="checkbox"
              value="not_helpful"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="checkbox-not_helpful" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              response is incorrect
            </label>
          </div>
          <div class="flex items-center pl-0.5">
            <input
              id="checkbox-incorrect"
              v-model="checkboxes"
              checked
              type="checkbox"
              value="incorrect"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="checkbox-incorrect" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              response is correct but incomplete
            </label>
          </div>
          <div class="flex items-center pl-0.5">
            <input
              id="checkbox-incomplete"
              v-model="checkboxes"
              checked
              type="checkbox"
              value="incomplete"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label for="checkbox-incomplete" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              response should be checked (unsure if correct)
            </label>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-700">
          <button
            data-modal-hide="dislikeModal"
            type="button"
            class="ml-auto text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-xl border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            Cancel
          </button>
          <button
            data-modal-hide="dislikeModal"
            type="button"
            :disabled="loading_save"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-center inline-flex items-center font-medium rounded-xl text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="save"
          >
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-3.5 h-3.5 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                        </svg> -->
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { initModals } from 'flowbite'
  import { onMounted, ref, watch } from 'vue'
  import axios from 'axios'

  export default {
    props: {
      chat_id: {
        type: String,
        default: '',
      },
      message: {
        type: Object,
        default: () => {},
      },
      message_position: {
        type: Number,
        default: 0,
      },
    },
    emits: ['update:message'],
    setup(props, context) {
      onMounted(() => {
        initModals()
      })

      const loading_save = ref(false)
      const description = ref(props.message?.feedback_description || '')
      const checkboxes = ref(props.message?.feedback_checkboxes || [])

      watch(
        () => props.message,
        newVal => {
          description.value = newVal?.feedback_description || ''
          checkboxes.value = newVal?.feedback_checkboxes || []
        }
      )

      const save = () => {
        loading_save.value = true

        const messageToSend = props.message

        messageToSend.feedback = 'dislike'
        messageToSend.feedback_description = description.value
        messageToSend.feedback_checkboxes = checkboxes.value

        axios
          .post(
            '/api/feedback',
            {
              message: messageToSend,
              chat_id: props.chat_id,
              message_position: props.message_position,
              description: description.value,
              checkboxes: checkboxes.value,
            },
            { withCredentials: true }
          )
          .then(() => {
            loading_save.value = false
            context.emit('update:message', messageToSend)
          })
          .catch(error => {
            console.log(error)
            loading_save.value = false
          })
      }

      return {
        loading_save,
        description,
        checkboxes,
        save,
        props,
      }
    },
  }
</script>

<template>
  <div id="users" class="p-4 rounded-xl flex flex-col gap-4" role="tabpanel" aria-labelledby="users-tab">
    <div class="max-w-[36rem]">
      <h1 class="text-base font-semibold">Allow Other Registrations</h1>
      <div class="w-full flex items-center justify-between gap-1 mt-1">
        <p class="text-[#808080] dark:text-[#B0B0B0]">Allow users of the same domain ({{ org.id }}) to register</p>
        <ToggleButton :active="allowRegisteration" @update:active="$event => toggleRegistration($event)" />
      </div>
      <div class="w-full flex items-center justify-between gap-1 mt-1">
        <p class="text-[#808080] dark:text-[#B0B0B0]">
          Allow admins to authenticate for users to enable SSO in embeds
          <a
            href="https://docs.getdot.ai/dot/whats-dot/embed"
            target="_blank"
            class="text-blue-600 dark:text-blue-300 hover:underline"
          >
            Learn more
          </a>
        </p>
        <ToggleButton :active="!disable_embedded_login" @update:active="$event => toggleEmbeddedLogin(!$event)" />
      </div>
    </div>

    <div class="max-w-[36rem]">
      <h1 class="text-base font-semibold">Handle New Users</h1>
      <div class="w-full flex items-center justify-between gap-1 mt-1">
        <p class="text-[#808080] dark:text-[#B0B0B0]">Default new users to admin role</p>
        <ToggleButton :active="newUserAdmin" @update:active="$event => toggleNewUserAdmin($event)" />
      </div>
      <div class="w-full flex items-center justify-between gap-1 mt-2">
        <p class="text-[#808080] dark:text-[#B0B0B0]">Assign new users to this group:</p>
        <select
          :value="newUserGroup"
          class="bg-gray-50 border border-gray-300 font-mono font-medium text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-1.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-max"
          @change="setNewUserGroup($event)"
        >
          <option v-for="(group, index) in allGroups" :key="index" :value="group">
            {{ group }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="org.okta_active || org.azure_active" class="max-w-[36rem]">
      <h1 class="text-base font-semibold">Disable registrations and password login</h1>
      <div class="w-full flex items-center justify-between gap-1 mt-1">
        <p class="text-[#808080] dark:text-[#B0B0B0]">
          Prohibit users to register to your Dot instance and to use password based access
        </p>
        <button
          class="w-10 h-5 rounded-xl relative flex-shrink-0"
          :class="{
            'bg-blue-600 border-blue-300 border-2': disablePasswordLogin,
            'bg-gray-200 dark:bg-gray-700 border-blue-200': !disablePasswordLogin,
          }"
          @click="toggleDisablePasswordLogin"
        >
          <span
            class="w-4 h-4 rounded-full bg-white block absolute top-1/2 -translate-y-1/2 shadow"
            :class="{
              'left-[1px]': !disablePasswordLogin,
              'right-[1px]': disablePasswordLogin,
            }"
          />
        </button>
      </div>
    </div>

    <!-- File Upload Section -->
    <div class="max-w-[36rem]">
      <h1 class="text-base font-semibold">Upload Logo</h1>
      <label for="fileUpload" class="block mb-1 text-[#808080] dark:text-[#B0B0B0]">
        Select a PNG or SVG file with your logo.
      </label>
      <div class="mt-1 flex flex-col md:flex-row gap-4 w-full max-w-[36rem]">
        <div class="relative flex items-center w-full max-w-[32rem] border dark:border-gray-800 rounded-2xl p-1">
          <label
            for="fileUpload"
            class="inline-block rounded-2xl bg-blue-600 text-white text-sm px-4 py-1 cursor-pointer hover:bg-blue-700"
          >
            Browse
          </label>
          <input id="fileUpload" type="file" accept=".png, .svg" class="hidden" @change="handleFileUpload" />
          <span v-if="selectedFile?.name" class="ml-2 text-sm text-gray-700 font-mono text-xs">
            {{ selectedFile.name }}
          </span>
          <img
            v-if="org.logo && !selectedFile"
            alt="logo preview"
            class="rounded-full w-[20px] h-[20px] object-contain ml-auto mr-2"
            src="/api/logo"
          />
        </div>

        <!-- Reset to default button -->
        <button
          class="ml-auto hover:bg-gray-100 dark:hover:bg-gray-800 text-sm px-4 py-1 rounded-2xl text-gray-700 dark:text-gray-300"
          @click="resetLogo"
        >
          Reset
        </button>
        <button
          :class="{
            'bg-blue-600 hover:bg-blue-700': selectedFile,
            'bg-gray-300 dark:bg-gray-800 dark:text-gray-600 cursor-not-allowed font-medium':
              !selectedFile || uploading,
          }"
          :disabled="!selectedFile || uploading"
          class="py-2 px-6 w-max shrink-0 text-white rounded-16 text-center flex items-center ml-auto self-start"
          @click="uploadFile"
        >
          {{ uploading ? 'Uploading...' : 'Upload File' }}
        </button>
      </div>
    </div>

    <div>
      <h1 class="text-base font-semibold">Invite Team Members</h1>
      <label for="#inviteMembers" class="block mb-1 text-[#808080] dark:text-[#B0B0B0]">
        Invite other people to join your team. Separate emails with a space.
      </label>
      <div class="mt-1 flex flex-col md:flex-row gap-4 w-full max-w-[36rem]">
        <div
          class="p-2.5 pr-5 relative w-full border max-w-[32rem] border-gray-300 bg-gray-50 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white focus-within:ring-blue-500 focus-within:border-blue-500 dark:focus-within:ring-blue-500 dark:focus-within:border-blue-500 rounded-16 overflow-hidden"
        >
          <div v-if="usersForInvite.length" class="mb-2 flex items-center flex-wrap gap-1">
            <span
              v-for="(userForInvite, index) in usersForInvite"
              :key="index"
              class="py-1 px-1.5 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500 dark:hover:bg-gray-400 rounded flex items-center gap-1 w-max cursor-pointer"
              @click="removeUserEmail(userForInvite)"
            >
              {{ userForInvite }}
              <CloseIcon class="w-[14px]" />
            </span>
          </div>
          <input
            id="inviteMembers"
            v-model="email"
            type="email"
            autocomplete="off"
            :placeholder="usersForInvite.length ? 'Add another email' : 'Enter email address'"
            class="block w-full text-sm border-0 outline-none p-0 focus:ring-0 text-gray-900 dark:text-white bg-transparent"
            @keyup.enter="addUserEmail(email)"
            @keyup.space="addUserEmail(email)"
            @change="addUserEmail(email)"
          />
          <PlusIcon
            class="absolute right-1 w-10 h-10 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500 dark:hover:text-white hover:text-gray-500 dark:hover:bg-gray-400 hover:bg-gray-200 rounded cursor-pointer"
            @click="addUserEmail(email)"
          />
        </div>
        <button
          :class="{
            'bg-blue-500 hover:bg-blue-600': usersForInvite.length || email.length,
            'bg-gray-300 dark:bg-gray-800 dark:text-gray-600 cursor-not-allowed font-medium':
              !(usersForInvite.length || email.length) || sendingInvite,
          }"
          :disabled="!(usersForInvite.length || email.length) || sendingInvite"
          class="py-2 px-6 w-max shrink-0 text-white rounded-16 text-center flex items-center ml-auto self-start"
          @click="inviteUsers"
        >
          {{ sendingInvite ? 'Sending...' : 'Send Invites' }}
        </button>
      </div>
    </div>

    <div class="mt-8">
      <div class="flex items-center justify-between">
        <Search
          :model-value="search_users"
          placeholder="Search..."
          @update:model-value="((search_users = $event), (page_users = 1))"
        />
        <button
          class="flex items-center px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-900 hover:dark:text-gray-100 rounded-md focus:outline-none ml-4"
          @click="downloadUserCSV"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 mr-2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
          Download Users
        </button>
      </div>

      <div class="table-wrapper">
        <table class="primary-table">
          <thead class="table-header">
            <tr>
              <th scope="col" class="px-6 py-3 text-left">User</th>
              <th scope="col" class="px-2 py-3 text-left">Role</th>
              <th scope="col" class="px-2 py-3 text-left">Groups</th>
              <th scope="col" class="px-2 py-3 text-center">Actions</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr
              v-for="item in filteredUserItems.slice((page_users - 1) * perPage_users, page_users * perPage_users)"
              :key="item.id"
              class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900"
              :class="{
                'rounded-bl-xl': item.id === filteredUserItems[filteredUserItems.length - 1].id,
              }"
              :data-testid="`user-${item.email}`"
            >
              <th
                v-tooltip="item.verified ? 'Active' : 'Inactive'"
                scope="row"
                alt="item.name"
                class="pl-6 pr-2 py-4 text-xs max-w-sm block truncate font-medium whitespace-nowrap"
                :class="{
                  'text-gray-500 dark:text-gray-500': !item.verified,
                  'text-gray-900 dark:text-white': item.verified,
                }"
              >
                <div class="flex items-center gap-1">
                  <!-- be careful to make changes here -->
                  <!-- private slack channels need to be hidden in name for users that are not in them, but show them when allowed_users list is empty for some reason -->
                  <!-- personal DM slack messages can be shown on the other hand -->
                  <!-- Direct messages (DMs): IDs start with D. Public and private channels: IDs start with C. -->
                  <span class="font-medium dark:text-white text-dark-text">
                    {{
                      !item.channel_id
                        ? item.name
                        : item.channel_id.startsWith('D')
                          ? item.name
                          : !item.allowed_users.includes(user.id) && item.allowed_users.length > 0
                            ? `Private Channel with ID: ` + item.channel_id
                            : item.name
                    }}
                  </span>
                  <span class="h-[2px] w-[2px] rounded-full bg-[#808080] dark:bg-[#B0B0B0]" />
                  <span class="text-[#808080] dark:text-[#B0B0B0]">
                    {{ !item.channel_id || item.channel_id.startsWith('D') ? item.email : `Slack Channel` }}
                  </span>
                </div>
              </th>
              <td
                class="pl-2 pr-2 py-1 text-xs max-w-2xl font-mono truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
              >
                <select
                  id="llm-model"
                  data-test="a5706fd6-9097-4559-b13c-2d3c58f14e19"
                  :value="item.role"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  @change="changeUserRole(item, $event)"
                >
                  <option value="admin" data-test="37eeaff8-f1a4-4373-8b85-56fcdcf88b90">Admin</option>
                  <option value="user" data-test="d79825dd-cb43-4cab-ab43-dbf84c28e32a">User</option>
                </select>
              </td>

              <td>
                <div class="flex items-center flex-row flex-wrap gap-1">
                  <span
                    v-for="(group, index) in item.groups.filter(g => g !== item.id)"
                    :key="index"
                    v-tooltip="`Remove ${group}`"
                    class="py-1 px-3 font-mono font-medium text-xs text-white bg-gray-950 hover:bg-gray-600 dark:text-dark-text dark:bg-white dark:hover:bg-gray-400 rounded-full flex items-center gap-1 w-max cursor-pointer"
                    @click="removeUserFromGroup(item, group)"
                  >
                    {{ group }}
                    <CloseIcon class="w-[14px]" />
                  </span>
                  <div v-if="item" class="grow">
                    <input
                      id="addGroups"
                      v-model="textInput[item.id]"
                      :placeholder="item.groups.length ? 'Add another group' : 'Add table to group'"
                      autocomplete="off"
                      class="block w-full text-xs border-0 font-mono font-medium outline-none p-0 pl-1 py-2 focus:ring-0 text-gray-900 dark:text-white bg-transparent"
                      type="text"
                      @keydown.tab.stop.prevent="addAutocomplete(item, $event, $event.target.value)"
                      @keyup="(showSuggestion(item), addUserToGroup(item, $event, $event.target.value))"
                    />
                    <div v-if="autoCompPlaceholder[item.id]" class="clearit virtual-text">
                      <div class="hidden-text text-xs font-mono font-medium">
                        {{ textInput[item.id] }}
                      </div>
                      <input
                        :placeholder="autoCompPlaceholder[item.id]"
                        class="autocomplete text-xs font-mono font-medium"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td
                class="py-1 text-center text-xs max-w-2xl font-mono font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
              >
                <!-- Dropdown button -->
                <div class="relative" @click.stop>
                  <button
                    class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-xl hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    type="button"
                    @click="$refs[`actions-${item.id}`][0].classList.toggle('hidden')"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
                      />
                    </svg>
                  </button>

                  <!-- Dropdown menu -->
                  <div
                    :ref="`actions-${item.id}`"
                    v-click-away="
                      () => {
                        const ref = $refs[`actions-${item.id}`]
                        if (ref && ref[0]) {
                          ref[0].classList.add('hidden')
                        }
                      }
                    "
                    class="z-10 w-64 hidden fixed right-20 top-[calc(100% + 10px)] bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-800 dark:divide-gray-700"
                  >
                    <ul
                      class="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownMenuIconHorizontalButton"
                    >
                      <li>
                        <button
                          class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                          @click.stop="(getApiToken(item), $refs[`actions-${item.id}`][0].classList.toggle('hidden'))"
                        >
                          Copy API Token
                        </button>
                      </li>
                      <li>
                        <button
                          class="block w-full px-4 py-2 text-red-700 dark:text-red-400 hover:bg-gray-100 dark:hover:bg-gray-700"
                          @click.stop="deleteUser(item)"
                        >
                          Delete User
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-wrapper">
        <!-- Help text -->
        <div class="flex gap-1 w-max shrink-0 items-center">
          <span class="text-gray-500 dark:text-gray-400 flex items-center gap-1">
            Showing
            <span class="font-semibold text-gray-600 dark:text-white">
              {{ (page_users - 1) * perPage_users + 1 }}
            </span>
            to
            <span class="font-semibold text-gray-600 dark:text-white">
              {{ Math.min(page_users * perPage_users, filteredUserItems.length) }}
            </span>
            of
            <span class="font-semibold text-gray-600 dark:text-white">{{ filteredUserItems.length }}</span>
          </span>

          <span class="text-gray-500 dark:text-gray-400 w-max shrink-0">· Rows per page</span>

          <select
            id="perPage_draawer"
            v-model="perPage_users"
            class="ml-2 cursor-pointer max-w-[80px] bg-gray-50 border border-gray-200 text-gray-800 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            @change="page_users = 1"
          >
            <option :value="10" selected>10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="filteredUserItems.length">All</option>
          </select>
        </div>

        <div class="sm:ml-auto pagination-btn-wrapper">
          <!-- Buttons -->
          <button class="pagination-btn-prev" @click="page_users = Math.max(page_users - 1, 1)">
            <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
            Prev
          </button>
          <button
            class="pagination-btn-next"
            @click="page_users = Math.min(page_users + 1, parseInt(users.length / perPage_users) + 1)"
          >
            Next
            <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>

    <div class="border border-red-500 mt-8 rounded-16 flex flex-col sm:flex-row items-center justify-between gap-4 p-4">
      <div class="flex items-center gap-4">
        <!-- <div
          class="rounded-12 sm:w-[46px] sm:h-[46px] w-10 h-10 flex center-child bg-[#FDE8E8] dark:bg-[#771D1D] shrink-0"
        >
          <Icon icon="hugeicons:alert-diamond" class="w-6 h-6 text-[#EF3E2B] dark:text-[#F8B4B4]" />
        </div> -->
        <div>
          <h1 class="text-base font-semibold">Danger Zone</h1>
          <label for="#deleteOrg" class="block text-[#808080] dark:text-[#B0B0B0]">
            Delete this organization and all associated data forever.
          </label>
        </div>
      </div>
      <button
        id="deleteOrg"
        class="!bg-red-500 hover:!bg-red-600 dark:hover:!bg-red-700 primary-btn"
        data-modal-target="deleteModal"
        data-modal-toggle="deleteModal"
      >
        Delete Organization
      </button>
    </div>

    <!-- Delete Modal -->
    <div
      id="deleteModal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-xl shadow dark:bg-gray-800">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-4 px-6 border-b rounded-t dark:border-gray-700">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Delete Confirmation</h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="deleteModal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6">
            <p class="text-gray-700 dark:text-gray-300">Are you sure you want to delete this organization?</p>
          </div>
          <!-- Modal footer -->
          <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button
              data-modal-hide="deleteModal"
              type="button"
              class="ml-auto text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-xl border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Cancel
            </button>
            <button
              data-modal-hide="deleteModal"
              type="button"
              :disabled="loading_delete"
              class="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 text-center inline-flex items-center font-medium rounded-xl text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              @click="deleteOrganization"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PlusIcon from '../icons/PlusIcon.vue'
  import CloseIcon from '../icons/CloseIcon.vue'
  import ToggleButton from '../ToggleButton.vue'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'
  import { useUserStore } from '@/stores/userStore'
  import { storeToRefs } from 'pinia'
  import { computed, onMounted, ref, watch } from 'vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import { initModals } from 'flowbite'

  export default {
    name: 'UsersTab',
    components: {
      PlusIcon,
      CloseIcon,
      ToggleButton,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const orgStore = useOrgStore()
      const { org, groups } = storeToRefs(orgStore)

      const userStore = useUserStore()
      const { user } = storeToRefs(userStore)

      const textInput = ref({})
      const autoCompPlaceholder = ref({})
      const users = ref([])
      const loading_delete = ref(false)

      const selectedFile = ref(null)
      const uploading = ref(false)

      console.log(org.value)

      const allowRegisteration = ref(org.value.allow_registration)
      const disable_embedded_login = ref(org.value.disable_embedded_login)
      const newUserAdmin = ref(org.value.new_user_admin)
      const newUserAddedToGroup = ref(org.value.new_user_group) || ref(false)
      const disablePasswordLogin = ref(org.value.disable_password_login)
      const newUserGroup = ref(org.value.new_user_group) || ref('all_users')

      watch(
        () => org.value,
        () => {
          allowRegisteration.value = org.value.allow_registration
          disable_embedded_login.value = org.value.disable_embedded_login
          newUserAdmin.value = org.value.new_user_admin
          newUserAddedToGroup.value = org.value.new_user_group
          disablePasswordLogin.value = org.value.disable_password_login
          newUserGroup.value = org.value.new_user_group || 'all_users'
        }
      )

      onMounted(() => {
        initModals()
      })

      const getUsers = () => {
        axios
          .get('/api/get_users', { withCredentials: true })
          .then(res => {
            users.value = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }

      const handleFileUpload = event => {
        selectedFile.value = event.target.files[0]
      }

      const uploadFile = () => {
        if (!selectedFile.value) return

        uploading.value = true
        const formData = new FormData()
        formData.append('file', selectedFile.value)

        axios
          .post('/api/upload_logo', formData, { withCredentials: true })
          .then(() => {
            notify.success('File uploaded successfully')
            // selectedFile.value = null
            uploading.value = false
          })
          .catch(err => {
            console.log(err)
            notify.error('Error uploading file')
            uploading.value = false
          })
      }

      // function to reset the logo with /api/reset_logo
      const resetLogo = () => {
        axios
          .post('/api/reset_logo', {}, { withCredentials: true })
          .then(() => {
            notify.success('Logo reset successfully')
            org.logo = null
          })
          .catch(err => {
            console.log(err)
            notify.error('Error resetting logo')
          })
      }

      const showSuggestion = doc => {
        if (!textInput.value[doc.id] || textInput.value[doc.id].length === 0) {
          autoCompPlaceholder.value[doc.id] = ''
          return
        }

        const possibleMatches = groups.value.filter(group => {
          return group.toLowerCase().startsWith(textInput.value[doc.id].toLowerCase()) && !doc.groups.includes(group)
        })

        if (possibleMatches.length > 0) {
          autoCompPlaceholder.value[doc.id] = possibleMatches[0].replace(textInput.value[doc.id], '')
        } else {
          autoCompPlaceholder.value[doc.id] = ''
        }
      }

      const addAutocomplete = (doc, event, group) => {
        if (!group) {
          group = textInput[doc.id]
        }

        group += autoCompPlaceholder.value[doc.id]
        group = group.trim().toLowerCase()

        if (!doc.groups.includes(group)) {
          axios
            .post('/api/add_user_to_group', { email: doc.email, group: group }, { withCredentials: true })
            .then(() => {
              getUsers()
              // Clear input
              textInput.value = {}
              autoCompPlaceholder.value = {}
            })
            .catch(err => {
              console.log(err)
            })
        }
      }

      const deleteOrganization = () => {
        loading_delete.value = true
        axios
          .delete('/api/org', {}, { withCredentials: true })
          .then(() => {
            window.location.href = '/'
          })
          .catch(err => {
            console.log(err)
            loading_delete.value = false
          })
      }

      const perPage_users = ref(10)
      const page_users = ref(1)
      const search_users = ref('')

      const filteredUserItems = computed(() => {
        return users.value.filter(item => {
          return item.email && item.email.toLowerCase().includes(search_users.value.toLowerCase())
        })
      })

      const downloadUserCSV = () => {
        // Start by creating a new CSV file and its header
        // We want to include email, name, role, groups, and verified status
        // groups is an array, so we need to join it into a string and escape any commas
        const csvContent = `data:text/csv;charset=utf-8,Email,Name,Role,Groups,Verified\n${filteredUserItems.value
          .map(user => {
            return `${user.email},${user.name},${user.role},${user.groups.filter(group => group !== user.id).join(';')},${user.verified ? 'Active' : 'Inactive'}`
          })
          .join('\n')}`

        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'users.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }

      return {
        allowRegisteration,
        disable_embedded_login,
        newUserAdmin,
        disablePasswordLogin,
        org,
        textInput,
        autoCompPlaceholder,
        users,
        showSuggestion,
        addAutocomplete,
        getUsers,
        notify,
        newUserAddedToGroup,
        newUserGroup,
        loading_delete,
        deleteOrganization,
        downloadUserCSV,
        filteredUserItems,
        perPage_users,
        page_users,
        search_users,
        user,
        selectedFile,
        uploading,
        handleFileUpload,
        uploadFile,
        resetLogo,
      }
    },
    data() {
      return {
        email: '',
        usersForInvite: [],
        sendingInvite: false,
        message: '',
        isSuccess: true,
      }
    },

    computed: {
      allGroups() {
        let groups = ['new_users', 'all_users']
        this.users.forEach(user => {
          let userGroups = Object.values(user.groups)
          // remove the group name that is the same as the user's id
          userGroups = userGroups.filter(group => group !== user.id)
          if (userGroups.length > 0) {
            userGroups.forEach(group => {
              if (!groups.includes(group)) {
                groups.push(group)
              }
            })
          }
        })
        return groups
      },
    },

    mounted() {
      this.getUsers()

      document.title = 'Users · Settings · Dot'
    },

    methods: {
      deleteUser(user) {
        user.loading = true
        axios
          .post('/api/delete_user', { email: user.email }, { withCredentials: true })
          .then(() => {
            this.getUsers()
            user.loading = false
          })
          .catch(err => {
            console.log(err)
            user.loading = false
          })
      },
      getApiToken(user) {
        axios
          .post('/api/get_api_token', { user_id: user.id }, { withCredentials: true })
          .then(res => {
            this.notify.success('API Token copied to clipboard')
            navigator.clipboard.writeText(res.data)
          })
          .catch(err => {
            console.log(err)
            this.notify.error('Error creating API Token')
          })
      },
      toggleRegistration(value) {
        this.allowRegisteration = value
        axios.post('/api/set_org_registration', { value }, { withCredentials: true })
      },
      toggleEmbeddedLogin(value) {
        this.disable_embedded_login = value
        axios.post('/api/set_disable_embedded_login', { value }, { withCredentials: true })
      },
      toggleNewUserAdmin(value) {
        this.newUserAdmin = value
        axios.post('/api/set_new_user_admin', { value }, { withCredentials: true })
      },
      setNewUserGroup(event) {
        this.newUserGroup = event.target.value
        axios.post('/api/set_new_user_group', { value: this.newUserGroup }, { withCredentials: true })
      },
      toggleDisablePasswordLogin() {
        this.disablePasswordLogin = !this.disablePasswordLogin

        axios.post('/api/set_disable_password_login', { value: this.disablePasswordLogin }, { withCredentials: true })
      },
      addUserEmail(emailString) {
        const validateEmail = email => {
          // Use a more robust regex for email validation
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }

        const emails = emailString
          .split(/[\s,;\n]+/) // Split on spaces, commas, semicolons, and newlines
          .map(email => email.trim())
          .filter(email => validateEmail(email)) // Validate and filter

        this.usersForInvite = [...new Set([...this.usersForInvite, ...emails])] // Combine and remove duplicates
        this.email = ''
      },
      removeUserEmail(email) {
        this.usersForInvite = this.usersForInvite.filter(user => user !== email)
      },
      inviteUsers() {
        this.addUserEmail(this.email)
        this.sendingInvite = true
        axios
          .post('/api/send_invitations', { emails: this.usersForInvite }, { withCredentials: true })
          .then(() => {
            this.usersForInvite = []
            this.getUsers()
            this.showNotification('Invitations have been sent', true)
            this.sendingInvite = false
          })
          .catch(err => {
            console.log(err)
            this.showNotification('Error sending invitations', false)
            this.sendingInvite = false
          })
      },
      changeUserRole(userToChange, event) {
        if (this.user && userToChange.role === 'admin' && userToChange.email === this.user.email) {
          this.notify.error('You cannot change your own role')

          // Reset the select element to the previous value
          event.target.value = 'admin'
          return
        }

        axios
          .post(
            '/api/change_user_role',
            { email: userToChange.email, role: event.target.value },
            { withCredentials: true }
          )
          .then(() => {
            this.getUsers()
            this.notify.success('User role changed')
          })
          .catch(err => {
            console.log(err)
          })
      },
      addUserToGroup(user, event) {
        // Only execute if keyup event is enter, space or comma
        if (event.keyCode !== 13 && event.keyCode !== 32 && event.keyCode !== 188) {
          return
        }

        const group = event.target.value.trim().toLowerCase()

        axios
          .post('/api/add_user_to_group', { email: user.email, group: group }, { withCredentials: true })
          .then(() => {
            this.getUsers()
            // Clear input
            this.textInput = {}
          })
          .catch(err => {
            console.log(err)
          })
      },
      removeUserFromGroup(user, group) {
        axios
          .post('/api/remove_user_from_group', { email: user.email, group }, { withCredentials: true })
          .then(() => {
            this.getUsers()
          })
          .catch(err => {
            console.log(err)
          })
      },
      showNotification(message, isSuccess) {
        if (isSuccess) {
          this.notify.success(message)
        } else {
          this.notify.error(message)
        }
      },
    },
  }
</script>

<template>
  <div
    class="text-sm font-medium flex items-center gap-3 cursor-pointer dark:placeholder-gray-400 dark:text-white transition-colors duration-300 ease-linear"
    role="button"
    tabindex="0"
    @click="toggleChecked"
  >
    <div
      class="rounded-full w-5 h-5 bg-white dark:bg-transparent border flex items-center justify-center shrink-0"
      :class="{ 'border-blue-700 dark:border-white': isChecked, 'border-gray-300': !isChecked }"
    >
      <div v-if="isChecked" class="rounded-full bg-blue-700 w-3 h-3" />
    </div>
    <label class="cursor-pointer">{{ label }}</label>
  </div>
</template>

<script>
  export default {
    name: 'SimpleRadioInput',
    props: {
      checked: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
    },
    emits: ['toggle'],

    computed: {
      isChecked() {
        return this.checked
      },
    },

    methods: {
      toggleChecked() {
        this.$emit('toggle', !this.isChecked)
      },
    },
  }
</script>

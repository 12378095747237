<template>
  <section class="bg-white dark:bg-[#141414] w-full text-gray-900 dark:text-white">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="flex items-center mb-6 text-2xl font-semibold">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>
      <div class="w-full rounded-24 border primary-border max-w-[440px] px-4 py-8 md:px-8 md:py-10">
        <div class="space-y-4 md:space-y-6">
          <h1 class="text-24 font-bold text-center">Sign in</h1>
          <form class="space-y-4 md:space-y-6" @submit.prevent="login">
            <button
              v-if="org && org.okta_active"
              id="sso-okta-button"
              type="button"
              class="outline-btn !w-full"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
              @click.prevent="login_with_sso('okta')"
            >
              Sign in with Okta
            </button>
            <button
              v-if="org && org.azure_active"
              id="sso-azure-button"
              type="button"
              class="outline-btn !w-full"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
              @click.prevent="login_with_sso('azure')"
            >
              <AzureIcon class-list="w-6 h-6 dark:fill-white" />
              Sign in with Azure
            </button>

            <hr
              v-if="!firstStep && (org.okta_active || org.azure_active) && !org.disable_password_login"
              class="border-t primary-border"
            />

            <div v-show="firstStep || !org.disable_password_login">
              <label for="email" class="primary-label">Your email</label>
              <input
                id="email"
                v-model="email"
                type="email"
                name="email"
                class="primary-input"
                placeholder="name@company.com"
                required
                @keyup.enter="getOrganizationFromEmail"
              />
            </div>
            <div v-show="!firstStep && !org.disable_password_login">
              <label for="password" class="primary-label">Password</label>
              <input
                id="password"
                type="password"
                name="password"
                placeholder="••••••••"
                class="primary-input"
                required
                @keyup.enter="login"
              />
            </div>
            <div v-if="!firstStep && !org.disable_password_login" class="flex items-center justify-between">
              <router-link to="/password" class="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
                Forgot password?
              </router-link>
            </div>
            <button
              v-if="firstStep"
              id="continue-button"
              type="button"
              class="primary-btn !w-full !font-medium"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
              @click="getOrganizationFromEmail"
            >
              Continue
            </button>
            <button
              v-if="!firstStep && !org.disable_password_login"
              id="login-button"
              type="submit"
              class="primary-btn !w-full !font-medium"
              data-test="fffc299d-8487-4543-9e16-1b3d1ff86a60"
              @click="login"
            >
              <LoadingIcon v-if="loading" class="w-6 h-6 text-white dark:text-gray-400 animate-spin" />

              Sign in
            </button>
            <p v-if="firstStep" class="text-sm text-gray-400 dark:text-gray-500 text-center">
              <!--              Forgot your password? <router-link to="/password"-->
              <!--                                            class="font-medium text-blue-600 hover:underline dark:text-blue-500">Reset password</router-link>-->
              <!--              <br />-->
              Don’t have an account yet?
              <router-link to="/register" class="font-medium text-blue-600 hover:underline dark:text-blue-500">
                Sign up
              </router-link>
              <br />
              <!-- show the following if the url does not start with eu.getdot.ai -->
              <span
                v-if="window ? window.location.hostname !== 'eu.getdot.ai' : true"
                class="text-sm mt-0 text-gray-400 dark:text-gray-500"
              >
                Region: US
                <a
                  href="https://eu.getdot.ai/login"
                  class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                >
                  Switch to EU
                </a>
              </span>
              <span v-else class="text-sm mt-0 text-gray-400 dark:text-gray-500">
                Region: EU
                <a
                  href="https://app.getdot.ai/login"
                  class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                >
                  Switch to US
                </a>
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import axios from 'axios'
  import { useOrgStore } from '@/stores/orgStore'
  import { useGlobalStore } from './stores/globalStore'
  import AzureIcon from './components/icons/AzureIcon.vue'
  import LoadingIcon from './components/icons/LoadingIcon.vue'
  import { useRoute } from 'vue-router'

  // store login state in local storage
  // https://stackoverflow.com/questions/2010892/storing-objects-in-html5-localstorage
  export default {
    name: 'LoginPage',
    components: {
      AzureIcon,
      LoadingIcon,
    },
    setup() {
      const orgStore = useOrgStore()
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const firstStep = ref(true)
      const email = ref('')
      const org = ref({})

      const route = useRoute()

      const login_with_sso = type => {
        const org_id = org.value.id
        window.location.href = `/api/auth/${org_id}/${type}/login`
      }

      const getOrganizationFromEmail = async () => {
        if (email.value === '') {
          return
        }

        org.value = await orgStore.getOrganizationFromEmail(email.value)

        firstStep.value = false

        if (org.value.disable_password_login) {
          // if password login is disabled, login with sso, if multiple sso are enabled, skip this step
          if (org.value.okta_active && org.value.azure_active) {
            return
          } else if (org.value.okta_active) {
            login_with_sso('okta')
          } else if (org.value.azure_active) {
            login_with_sso('azure')
          }
        }
      }

      onMounted(() => {
        // There can be the parameters error=login_failed&reason=" + error_reason in the url
        // if the login failed, show the error message
        // use the notify function to show the error message
        // and the route object to get the query parameters
        const error = route.query.error
        const reason = route.query.reason
        if (error === 'login_failed') {
          notify.error(reason)
        }
      })

      return {
        email,
        firstStep,
        org,
        getOrganizationFromEmail,
        notify,
        login_with_sso,
      }
    },
    data() {
      return {
        window,
        message: '',
        isSuccess: true,
        loading: false,
      }
    },
    methods: {
      async login() {
        // get inputs fields
        const email = document.getElementById('email').value
        const password = document.getElementById('password').value

        if (email === '' || password === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        // create a new FormData object and append email and password to it
        const formData = new FormData()
        formData.append('username', email)
        formData.append('password', password)

        // call /auth/token login api with email and password using axios
        try {
          this.loading = true

          const response = await axios.post('/api/auth/token', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })

          if (response && response.data && response.data.access_token) {
            const { data } = response
            console.log(data)

            // store login state in local storage
            localStorage.setItem('login', JSON.stringify({ email }))
            this.$router.push('/')
          }
        } catch (error) {
          this.notify.error(error.response.data.detail || error.response.data.message || error.message || error)
          console.log('Login failed:', error)

          // if status is 403 redirect to email verification page
          if (error.response.status === 403) {
            this.message = 'Please verify your email. Redirecting in 3 seconds...'
            this.notify.success(this.message)
            // wait 3 seconds before redirecting to email verification page
            await new Promise(resolve => setTimeout(resolve, 3000))

            this.$router.push('/verify?username=' + email)
          }
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style></style>

<template>
  <ConnectionWrapper
    connection-name="MySQL / MariaDB"
    :connection-is-active="!!mutableConnection.last_sync"
    :connection="mutableConnection"
  >
    <template #icon>
      <MySQLIcon class-list="w-6 h-6" />
    </template>

    <div>
      <div class="mb-6">
        <label for="mysql-host" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Host</label>
        <input
          id="mysql-host"
          v-model="mutableConnection.host"
          type="text"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="private_db.example.com"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mysql-port" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Port</label>
        <input
          id="mysql-port"
          v-model="mutableConnection.port"
          type="text"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="5432"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mysql-username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Username
        </label>
        <input
          id="mysql-username"
          v-model="mutableConnection.username"
          type="text"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="DOT"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mysql-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Password
        </label>
        <input
          id="mysql-password"
          v-model="mutableConnection.password"
          type="password"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        />
      </div>
      <div class="mb-6">
        <label for="mysql-database" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Database
        </label>
        <input
          id="mysql-database"
          v-model="mutableConnection.database"
          type="text"
          :disabled="!computedEditMode"
          autocomplete="off"
          :class="{ 'opacity-50': !computedEditMode }"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="PROD_DB"
        />
      </div>
      <div class="flex items-center gap-4 flex-wrap">
        <button
          v-if="editMode"
          class="text-gray-700 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:text-gray-400 dark:hover:bg-gray-800 dark:focus:ring-blue-800"
          @click="editMode = false"
        >
          Cancel
        </button>
        <button
          v-if="hasLastSync && editMode"
          class="text-red-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-xl text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-gray-950 dark:hover:bg-gray-800 dark:focus:ring-red-800"
          @click.prevent="
            emit('showRemoveModal', {
              ...mutableConnection,
              ...{
                title: mutableConnection.host,
              },
            })
          "
        >
          <span>Remove</span>
        </button>
        <button
          v-if="computedEditMode"
          :disabled="isLoading || !requiredFieldsFilled"
          class="connection-sync-btn"
          @click.prevent="connect"
        >
          <LoadingIcon v-if="isLoading" class="inline w-4 h-4 mr-3 text-white animate-spin" />
          <span v-if="isLoading">Loading...</span>
          <span v-else>Connect</span>
        </button>
        <button v-if="!computedEditMode" class="connection-edit-btn" @click.stop.prevent="editMode = true">Edit</button>
        <button v-if="hasLastSync && !computedEditMode" class="connection-sync-btn" @click.prevent="sync">
          <LoadingIcon v-if="isLoading" class="inline w-4 h-4 mr-3 text-white animate-spin" />
          <span v-if="isLoading">Loading...</span>
          <span v-else>Sync</span>
        </button>
      </div>
      <div
        v-if="hasLastSync"
        v-tooltip="mutableConnection.last_sync"
        class="text-xs mt-2 text-gray-500 dark:text-gray-400 hover:underline cursor-pointer"
        @click="emit('showLastLogs', 'mysql')"
      >
        Last synced {{ lastSyncDistanceString }}
      </div>
    </div>
  </ConnectionWrapper>
</template>

<script setup>
  import ConnectionWrapper from './ConnectionWrapper.vue'
  import { computed, defineEmits, defineProps, ref } from 'vue'
  import { formatDistanceToNow } from 'date-fns'
  import MySQLIcon from '@/components/icons/MySQLIcon.vue'
  import LoadingIcon from '@/components/icons/LoadingIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'

  const globalStore = useGlobalStore()
  const notify = globalStore.notify

  const orgStore = useOrgStore()

  const editMode = ref(false)
  const isLoading = ref(false)

  const emit = defineEmits(['update:connection_info', 'showLastLogs', 'showRemoveModal', 'updateConnections'])

  const props = defineProps({
    connection_info: {
      type: Object,
      default: () => ({}),
    },
    sync_connection: {
      type: Function,
      required: true,
    },
  })

  const mutableConnection = ref(props.connection_info)

  const computedEditMode = computed(() => !mutableConnection.value.last_sync || editMode.value)
  const hasLastSync = computed(() => mutableConnection.value.last_sync)
  const lastSyncDistanceString = computed(() => {
    if (mutableConnection.value.last_sync && mutableConnection.value.last_sync.includes('+')) {
      return formatDistanceToNow(new Date(mutableConnection.value.last_sync), { addSuffix: true })
    } else {
      return ''
    }
  })

  const requiredFieldsFilled = computed(() => {
    return (
      mutableConnection.value.host &&
      mutableConnection.value.port &&
      mutableConnection.value.username &&
      mutableConnection.value.password &&
      mutableConnection.value.database
    )
  })

  const connect = async () => {
    if (!requiredFieldsFilled.value) {
      notify.error('Please fill in all fields')
      return
    }

    isLoading.value = true

    try {
      let response = await axios.post(`/api/connect_mysql`, mutableConnection.value)
      emit('updateConnections')

      notify.success(response.data)
      orgStore.getConnections(true)

      await props.sync_connection('mysql', mutableConnection.value.host)

      editMode.value = false
    } catch (error) {
      console.error(error)
      notify.error(error?.response?.data?.detail || 'An error occurred')
    } finally {
      isLoading.value = false
    }
  }

  const sync = async () => {
    isLoading.value = true

    await props.sync_connection('mysql', mutableConnection.value.host)

    isLoading.value = false
  }
</script>

import { createRouter, createWebHistory } from 'vue-router'
import SettingsPage from './SettingsPage.vue'
import LoginPage from './LoginPage.vue'
import RegisterPage from './RegisterPage.vue'
import VerifyPage from './VerifyPage.vue'
import ChatPage from './ChatPage.vue'
import ModelEvalPage from './ModelEvalPage.vue'
import HistoryPage from './HistoryPage.vue'
import LogoutPage from './LogoutPage.vue'
import PasswordPage from './PasswordPage.vue'
import SharePage from './SharePage.vue'

import { useUserStore } from '@/stores/userStore'
import { createPinia, storeToRefs } from 'pinia'
import DesignSystemPage from './DesignSystemPage.vue'
import SurveyPage from './SurveyPage.vue'

const history = createWebHistory()

const adminOnly = async ({ next }) => {
  const pinia = createPinia()
  const userStore = useUserStore(pinia)
  const { user } = storeToRefs(userStore)

  if (!user.value.id) {
    await userStore.checkLogin()
  }

  if (user.value.role && user.value.role !== 'admin') {
    return next('/')
  }

  return next()
}

const router = createRouter({
  history,
  routes: [
    {
      path: '/',
      name: 'Chat',
      component: ChatPage,
    },
    {
      path: '/new',
      name: 'New Chat',
      component: ChatPage,
    },
    {
      path: '/settings',
      name: 'Settings',
      component: SettingsPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/settings/connections',
      name: 'SettingsConnections',
      component: SettingsPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/settings/modules',
      name: 'SettingsModules',
      component: SettingsPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/settings/skills',
      name: 'SettingsSkills',
      component: SettingsPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/settings/billing',
      name: 'SettingsBilling',
      component: SettingsPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/settings/users',
      name: 'SettingsUsers',
      component: SettingsPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/model',
      name: 'Model',
      component: ModelEvalPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/evaluation/question',
      name: 'EvaluationQuestion',
      component: ModelEvalPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/evaluation/timestamp',
      name: 'EvaluationTimestamp',
      component: ModelEvalPage,
      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/evaluation',
      name: 'Evaluation',
      component: ModelEvalPage,

      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/external-assets',
      name: 'ExternalAssets',
      component: ModelEvalPage,

      meta: {
        middleware: [adminOnly],
      },
    },
    {
      path: '/history',
      name: 'History',
      component: HistoryPage,
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
    },
    {
      path: '/password',
      name: 'Password',
      component: PasswordPage,
    },
    {
      path: '/invite/:email/:token',
      name: 'Invite',
      component: PasswordPage,
    },
    {
      path: '/register',
      name: 'Register',
      component: RegisterPage,
    },
    {
      path: '/verify',
      name: 'Verify',
      component: VerifyPage,
    },
    {
      path: '/logout',
      name: 'Logout',
      component: LogoutPage,
    },
    {
      path: '/share/:cid',
      name: 'Share',
      component: SharePage,
    },
    {
      path: '/design',
      name: 'DesignSystem',
      component: DesignSystemPage,
    },
    {
      path: '/survey',
      name: 'Survey',
      component: SurveyPage,
    },
  ],
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router

<template>
  <svg
    id="svg231"
    :class="classList ? classList : 'w-6 h-6'"
    width="74.781998mm"
    height="81.217003mm"
    viewBox="0 0 74.781998 81.217003"
    version="1.1"
    inkscape:version="1.2.2 (b0a8486541, 2022-12-01)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs228" />
    <g id="layer1" inkscape:label="Layer 1" inkscape:groupmode="layer">
      <path
        id="path2"
        fill-rule="nonzero"
        fill="rgb(93.725586%, 24.313354%, 16.862488%)"
        fill-opacity="1"
        d="m 0,21.217649 v 2.434995 l 37.39168,21.04402 33.5635,-18.782661 V 33.56625 L 37.39168,52.522545 1.91271,32.348066 0,33.39124 v 14.608581 l 37.39168,21.044023 33.5635,-18.782661 v 7.478613 L 37.39168,76.696087 1.91271,56.521609 0,57.564783 v 2.610005 L 37.39168,81.217432 74.78199,60.174788 V 45.566208 L 73.04291,44.521654 37.39168,64.696133 3.99906,45.566208 V 38.087594 L 37.39168,56.870252 74.78199,35.826233 V 21.391282 L 73.04291,20.348108 37.39168,40.522587 5.73952,22.60947 37.39168,4.69635 63.65158,19.478567 65.91294,18.088125 V 16.174031 L 37.39168,0 Z m 0,0"
        style="
          font-variation-settings: normal;
          vector-effect: none;
          fill: #ef3e2b;
          fill-opacity: 1;
          stroke-width: 0.352778;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 0;
          stroke-opacity: 1;
          -inkscape-stroke: none;
          stop-color: #000000;
        "
      />
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'DatabricksIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>

<!-- Settings Drawer -->
<template>
  <div
    class="container px-[24px] pb-8 overflow-y-scroll bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full"
  >
    <div class="tab-wrapper">
      <button
        id="connection-tab"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'model',
          'inactive-tab': activeTab !== 'model',
        }"
        type="button"
        role="tab"
        aria-controls="model"
        aria-selected="false"
        @click="changeTab('model')"
      >
        Model
      </button>
      <button
        id="connection-tab"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'evaluation',
          'inactive-tab': activeTab !== 'evaluation',
        }"
        type="button"
        role="tab"
        aria-controls="evaluation"
        aria-selected="false"
        @click="changeTab('evaluation')"
      >
        Evaluation
      </button>
      <button
        id="connection-tab"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'external-assets',
          'inactive-tab': activeTab !== 'external-assets',
        }"
        type="button"
        role="tab"
        aria-controls="external-assets"
        aria-selected="false"
        @click="changeTab('external-assets')"
      >
        External Assets
      </button>
    </div>
    <div id="myTabContent">
      <ModelTab v-if="activeTab === 'model'" />
      <EvaluationTab v-else-if="activeTab === 'evaluation'" />
      <ExternalAssetTab v-else-if="activeTab === 'external-assets'" />
    </div>
  </div>

  <!-- notification toast messages bind it to changes to variable message-->
</template>

<script>
  import { useRouter } from 'vue-router'
  import { useGlobalStore } from './stores/globalStore'
  import ModelTab from '@/components/ModelTab.vue'
  import EvaluationTab from '@/components/EvaluationTab.vue'
  import ExternalAssetTab from '@/components/ExternalAssetTab.vue'

  export default {
    name: 'ModelEvalPage',
    components: {
      ModelTab,
      EvaluationTab,
      ExternalAssetTab,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify
      const router = useRouter()

      const changeTab = tab => {
        router.push(`/${tab}`)
      }

      return {
        changeTab,
        notify,
      }
    },

    computed: {
      activeTab() {
        if (
          this.$route.path.replace('/', '') === 'evaluation/question' ||
          this.$route.path.replace('/', '') === 'evaluation/timestamp'
        ) {
          return 'evaluation'
        }
        return this.$route.path.replace('/', '')
      },
    },
  }
</script>
